import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    tourId: "00000000-0000-0000-0000-000000000000",
    destinationId: "00000000-0000-0000-0000-000000000000",
    name: "",
    type: "",
    travelDate: "",
    returnDate: "",
    nights: 0,
    totalPurchasePrice: 0,
    totalSalePrice: 0,
    pricePerPersons: 0,
    status: "",
    notes: "",
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
    tourFlights: [],
    tourHotels: [],
    tourItineraries: [],
    tourInclusion: [],
    tourExclusion: [],
    tourTerms: []
};
const tourFlightInitialFieldValues = {
    airlines: "",
    departureDate: new Date(),
    arrivalDate: new Date(),
    locationFrom: "",
    locationTo: ""
};
const tourHotelInitialFieldValues = {
    city: "",
    checkIn: new Date(),
    checkOut: new Date(),
    hotelName: "",
    roomCategory: "",
    meals: ""
};
const tourInitialFieldValues = {
    title: "",
    description: ""
};
const tourInclusionFieldValues = {
    inclusion: ""
};
const tourExclusionFieldValues = {
    exclusion: ""
};
const tourTermsInitialFieldValues = {
    terms: ""
};
export default function CreateTour() {
    const navigate = useNavigate();
    const [destinations, setDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [tfValues, setTFValues] = useState(tourFlightInitialFieldValues);
    const [thValues, setTHValues] = useState(tourHotelInitialFieldValues);
    const [tourValues, setTourValues] = useState(tourInitialFieldValues);
    const [tiValues, setTIValues] = useState(tourInclusionFieldValues);
    const [teValues, setTEValues] = useState(tourExclusionFieldValues);
    const [ttValues, setTTValues] = useState(tourTermsInitialFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [tourItineraryState, setTourItineraryState] = useState([]);
    const [tourFlightState, setTourFlightState] = useState([]);
    const [tourHotelState, setTourHotelState] = useState([]);
    const [tourTermState, setTourTermState] = useState([]);
    const [tourInclusionState, setTourInclusionState] = useState([
        { id: 1, inclusion: 'Transportation by vehicle with A/C' },
        { id: 2, inclusion: 'Private airport transfers and private tour transfers' },
        { id: 3, inclusion: 'Daily Breakfast' },
    ]);
    const [tourExclusionState, setTourExclusionState] = useState([
        { id: 1, exclusion: 'Expenses of personal nature such as tips, telephone calls, laundry, liquor, etc.' },
        { id: 2, exclusion: 'Anything that is not specified in Inclusions' },
        { id: 3, exclusion: 'Peak / Festival Period Surcharge to be paid extra & will be notified at time of booking confirmation' },
    ]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        let val = parseInt(e.target.value, 10);
        if (isNaN(val)) {
            setValues({
                ...values,
                [name]: "",
            });
        } else {
            val = val >= 0 ? val : 0;
            setValues({
                ...values,
                [name]: val,
            });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleFlightInputChange = (e) => {
        const { name, value } = e.target;
        setTFValues({
            ...tfValues,
            [name]: value,
        });
    };
    const handleHotelInputChange = (e) => {
        const { name, value } = e.target;
        setTHValues({
            ...thValues,
            [name]: value,
        });
    };
    const handleTourInputChange = (e) => {
        const { name, value } = e.target;
        setTourValues({
            ...tourValues,
            [name]: value,
        });
    };
    const handleTIInputChange = (e) => {
        const { name, value } = e.target;
        setTIValues({
            ...tiValues,
            [name]: value,
        });
    };
    const handleTEInputChange = (e) => {
        const { name, value } = e.target;
        setTEValues({
            ...teValues,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.nights = values.nights === 0 ? false : true;
        temp.totalSalePrice = values.totalSalePrice === 0 ? false : true;
        temp.pricePerPersons = values.pricePerPersons === 0 ? false : true;
        temp.type = values.type === "0" ? false : true;
        temp.destinationIdId = values.destinationIdId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("tourId", values.tourId)
            formData.append("destinationId", values.destinationId)
            formData.append("name", values.name)
            formData.append("nights", values.nights)
            formData.append("type", values.type)
            formData.append("travelDate", values.travelDate)
            formData.append("returnDate", values.returnDate)
            formData.append("totalPurchasePrice", values.totalPurchasePrice)
            formData.append("totalSalePrice", values.totalSalePrice)
            formData.append("pricePerPersons", values.pricePerPersons)
            formData.append("status", values.status)
            formData.append("description", values.description)
            formData.append("imageName", values.imageName)
            formData.append("imageFile", values.imageFile)
            formData.append("tourFlights", JSON.stringify(tourFlightState))
            formData.append("tourHotels", JSON.stringify(tourHotelState))
            formData.append("tourItineraries", JSON.stringify(tourItineraryState))
            formData.append("tourTerms", JSON.stringify(tourTermState))
            formData.append("tourInclusions", JSON.stringify(tourInclusionState))
            formData.append("tourExclusions", JSON.stringify(tourExclusionState))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATETOUR, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('tourId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        setBtnSubmit(true);
                        navigate('/tours')
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setTourValues(tourInitialFieldValues)
        setErrors({});
    };
    const GetHolidayDestinations = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setDestinations(response.data.data);
            });
    };
    // Function to add a new item to the flight
    const addTFItem = () => {
        const newItem = { id: tourFlightState.length + 1, airlines: tfValues.airlines, departureDate: moment(tfValues.departureDate).format('YYYY-MM-DD HH:MM A'), arrivalDate: moment(tfValues.arrivalDate).format('YYYY-MM-DD HH:MM A'), locationFrom: tfValues.locationFrom, locationTo: tfValues.locationTo };
        setTourFlightState([...tourFlightState, newItem]);
        setTFValues(tourFlightInitialFieldValues)
    };
    const removeTFItem = (id) => {
        const updatedItems = tourFlightState.filter(item => item.id !== id);
        setTourFlightState(updatedItems);
    };

    // Function to add a new item to the hotel
    const addTHItem = () => {
        const newItem = { id: tourHotelState.length + 1, city: thValues.city,hotelName: thValues.hotelName,meals: thValues.meals, roomCategory: thValues.roomCategory, checkIn: moment(thValues.checkIn).format('YYYY-MM-DD HH:MM A'), checkOut: moment(thValues.arrivalDate).format('YYYY-MM-DD HH:MM A')};
        setTourHotelState([...tourHotelState, newItem]);
        setTHValues(tourHotelInitialFieldValues)
    };
    const removeTHItem = (id) => {
        const updatedItems = tourHotelState.filter(item => item.id !== id);
        setTourHotelState(updatedItems);
    };

    // Function to add a new item to the itinerary
    const addTIItem = (index) => {
        const newItem = { id: tourItineraryState.length + 1, title: tourValues.title, description: tourValues.description };
        console.log(newItem)
        setTourItineraryState([...tourItineraryState, newItem]);
        setTourValues(tourInitialFieldValues)
    };
    // Function to remove an item from the itinerary
    const removeTIItem = (id) => {
        console.log(id)
        const updatedItems = tourItineraryState.filter(item => item.id !== id);
        setTourItineraryState(updatedItems);
    };

    // Function to add a new item to the inclusion
    const addTINItem = () => {
        const newItem = { id: tourInclusionState.length + 1, inclusion: tiValues.inclusion };
        console.log(newItem)
        setTourInclusionState([...tourInclusionState, newItem]);
        setTIValues(tourInclusionFieldValues)
    };
    // Function to remove an item from the array inclusion
    const removeTINItem = (id) => {
        console.log(id)
        const updatedItems = tourInclusionState.filter(item => item.id !== id);
        setTourInclusionState(updatedItems);
    };
    // Function to add a new item to the exclusion
    const addTEItem = () => {
        const newItem = { id: tourExclusionState.length + 1, exclusion: teValues.exclusion };
        console.log(newItem)
        setTourExclusionState([...tourExclusionState, newItem]);
        setTEValues(tourExclusionFieldValues)
    };
    // Function to remove an item from the array exclusion
    const removeTEItem = (id) => {
        console.log(id)
        const updatedItems = tourExclusionState.filter(item => item.id !== id);
        setTourExclusionState(updatedItems);
    };
    const applyErrorClass = (field) => field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetHolidayDestinations();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Create Package</h4>
                                    <div className="hstack gap-2 justify-content-end">
                                        {
                                            btnSubmit === true ?
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">{values.tourId === "00000000-0000-0000-0000-000000000000" ? "Save" : "Update"}</button>
                                                :
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="alert alert-success">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Destination</label>
                                                    <Select options={destinations} onChange={e => setValues({ ...values, ['destinationId']: e.value })}></Select>
                                                    {errors.holidayDestinationId === false ? (<div className="validationerror">Please select destination </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">Package Name</label>
                                                    <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Amazing Dubai" />
                                                    {errors.name === false ? (<div className="validationerror">Please enter package name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="type" className="form-label">Tour Type</label>
                                                    <select name="type" value={values.type} onChange={handleInputChange} className={"form-select" + applyErrorClass('type')}>
                                                        <option value="0">Please Select</option>
                                                        <option value="HONEYMOON">Honeymoon</option>
                                                        <option value="FIXED">Fixed Tour</option>
                                                        <option value="FAMILY">Family Tour</option>
                                                        <option value="GROUP">Group Tour</option>
                                                        <option value="DAILY">Daily Tour</option>
                                                        <option value="CORPORATE">Corporate</option>
                                                    </select>
                                                    {errors.type === false ? (<div className="validationerror">Please select tourType </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="travelDate" className="form-label">Travel Date</label>
                                                <input name="travelDate" type="date" value={values.travelDate} onChange={handleInputChange} className={"form-control" + applyErrorClass('travelDate')}></input>
                                                {errors.travelDate === false ? (<div className="validationerror">Please select travelDate </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="returnDate" className="form-label">Return Date</label>
                                                <input name="returnDate" type="date" value={values.returnDate} onChange={handleInputChange} className={"form-control" + applyErrorClass('returnDate')}></input>
                                                {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Nights</label>
                                                <input type="number" name="nights" value={values.nights} onChange={handleInputChange} className={"form-control" + applyErrorClass('nights')}></input>                                                {errors.noOfNights === false ? (<div className="validationerror">Please select nights </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="netPrice" className="form-label">Purchase Price</label>
                                                    <input type="number" min={0} value={values.totalPurchasePrice} name="totalPurchasePrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('totalPurchasePrice')} placeholder="0" />
                                                    {errors.totalPurchasePrice === false ? (<div className="validationerror">Please enter supplier price </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="totalSalePrice" className="form-label">Sale Price</label>
                                                    <input type="number" min={0} value={values.totalSalePrice} name="totalSalePrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('totalSalePrice')} placeholder="0" />
                                                    {errors.totalSalePrice === false ? (<div className="validationerror">Please enter salePrice </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="pricePerPersons" className="form-label">Price Per Person(s)</label>
                                                    <select name="pricePerPersons" value={values.pricePerPersons} onChange={handleInputChange} className={"form-select" + applyErrorClass('pricePerPersons')}>
                                                        <option value={1}>1 Person</option>
                                                        <option value={2}>2 Persons</option>
                                                        <option value={3}>3 Persons</option>
                                                        <option value={4}>4 Persons</option>
                                                        <option value={5}>5 Persons</option>
                                                    </select>
                                                    {errors.pricePerPersons === false ? (<div className="validationerror">Please select persons </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Status</label>
                                                    <select name="status" value={values.status} onChange={handleInputChange} className={"form-select" + applyErrorClass('status')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="INACTIVE">INACTIVE</option>
                                                    </select>
                                                    {errors.status === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <input type="text" value={values.description} name="description" onChange={handleInputChange} className={"form-control" + applyErrorClass('description')} />
                                                    {errors.description === false ? (<div className="validationerror">Please enter description </div>) : ('')}
                                                </div>
                                            </div>

                                            <div className="col-lg-3">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-flight" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Flights</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-hotel" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Hotels</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-overview" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Tour Itinerary</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-account" /></span>
                                                    <span className="d-none d-sm-block">Inclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Exclusions</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Airlines</th>
                                                            <th>Location From</th>
                                                            <th>Location To</th>
                                                            <th>Departure Date</th>
                                                            <th>Arrival Date</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourFlightState.length > 0 && tourFlightState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.airlines}</td>
                                                                <td>{v.locationFrom}</td>
                                                                <td>{v.locationTo}</td>
                                                                <td>{v.departureDate}</td>
                                                                <td>{v.arrivalDate}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTFItem(v.id)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.airlines} className="form-control" onChange={handleFlightInputChange} name="airlines"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.locationFrom} className="form-control" onChange={handleFlightInputChange} name="locationFrom"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.locationTo} className="form-control" onChange={handleFlightInputChange} name="locationTo"></input>
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tfValues.departureDate} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTFValues({ ...tfValues, ['departureDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tfValues.arrivalDate} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTFValues({ ...tfValues, ['arrivalDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <button onClick={addTFItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-hotel" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>City</th>
                                                            <th>Hotel Name</th>
                                                            <th>Room Category</th>
                                                            <th>Meal</th>
                                                            <th>Check-In</th>
                                                            <th>Check-Out</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourHotelState.length > 0 && tourHotelState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.city}</td>
                                                                <td>{v.hotelName}</td>
                                                                <td>{v.roomCategory}</td>
                                                                <td>{v.meals}</td>
                                                                <td>{v.checkIn}</td>
                                                                <td>{v.checkOut}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTHItem(v.id)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.city} className="form-control" onChange={handleHotelInputChange} name="city"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.hotelName} className="form-control" onChange={handleHotelInputChange} name="hotelName"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.roomCategory} className="form-control" onChange={handleHotelInputChange} name="roomCategory"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tfValues.meals} className="form-control" onChange={handleHotelInputChange} name="meals"></input>
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={thValues.checkIn} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTHValues({ ...thValues, ['checkIn']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={thValues.checkOut} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput onChange={(date) => setTHValues({ ...thValues, ['checkOut']: date })} />
                                                            </td>
                                                            <td>
                                                                <button onClick={addTHItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-overview" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourItineraryState.length > 0 && tourItineraryState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.title}</td>
                                                                <td>{v.description}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTIItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tourValues.title} className="form-control" onChange={handleTourInputChange} name="title"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tourValues.description} className="form-control" onChange={handleTourInputChange} name="description"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTIItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourInclusionState.length > 0 && tourInclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTINItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tiValues.inclusion} className="form-control" onChange={handleTIInputChange} name="inclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTINItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourExclusionState.length > 0 && tourExclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeTEItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={teValues.exclusion} className="form-control" onChange={handleTEInputChange} name="exclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addTEItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}