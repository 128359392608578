import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    destinationId: "00000000-0000-0000-0000-000000000000",
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
};
export default function Destinations() {
    const [holidayDestinations, setHolidayDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(1);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        temp.name = values.name === "" ? false : true;
        temp.country = values.country === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("destinationId", values.destinationId)
            formData.append("destinationName", values.destinationName)
            formData.append("imageName", values.imageName)
            formData.append("imageFile", values.imageFile)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEDESTINATION, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEDESTINATION, updateRecord, { ...headerconfig }),
            delete: (id) => axios.delete(config.APIACTIVATEURL + config.DELETEDESTINATION + "/" + id, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('destinationId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetHolidayDestinations(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        resetForm();
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                    resetForm();
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetHolidayDestinations(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        resetForm();
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    resetForm();
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetHolidayDestinations(totalPages)
    }
    const GetFirstPageData = () => {
        GetHolidayDestinations("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetHolidayDestinations(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this folder?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEDESTINATION + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetHolidayDestinations(pageNumber);
                        resetForm();
                    }
                    else {
                        handleError(response.data.data);
                        GetHolidayDestinations(pageNumber);
                        resetForm();
                    }
                });
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const GetHolidayDestinations = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETDESTINATIONS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setHolidayDestinations(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetHolidayDestinations(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                        <div className="file-manager-content w-100 p-3 py-0">
                            <div className="mx-n3 pt-4 px-4 file-manager-content-scroll" data-simplebar>
                                <div id="folder-list" className="mb-2">
                                    <div className="row justify-content-beetwen g-2 mb-3">
                                        <div className="col">
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-2 d-block d-lg-none">
                                                    <button type="button" className="btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn">
                                                        <i className="ri-menu-2-fill align-bottom" />
                                                    </button>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-16 mb-0">Destinations</h5>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end col*/}
                                        <div className="col-auto">
                                            <div className="d-flex gap-2 align-items-start">
                                                <button className="btn btn-primary w-sm create-folder-modal flex-shrink-0" data-bs-toggle="modal" data-bs-target="#createFolderModal"><i className="ri-add-line align-bottom me-1" /> Create Destinations</button>
                                                <Link className="btn btn-success w-sm create-folder-modal flex-shrink-0" to={"/createtour"}><i className="ri-add-line align-bottom me-1" /> Create Tour</Link>
                                            </div>
                                        </div>
                                        {/*end col*/}
                                    </div>
                                    {/*end row*/}
                                    <div className="row" id="folderlist-data">
                                        {holidayDestinations.length > 0 && holidayDestinations.map(hd =>
                                            <div className="col-xxl-2 col-6 folder-card" key={hd.destinationId}>
                                                <div className="card bg-light shadow-none" id="folder-1">
                                                    <div className="card-body">
                                                        <Link><div className="text-center">
                                                            <div className="mb-2">
                                                                <i className="ri-folder-2-fill align-bottom text-warning display-5" />
                                                            </div>
                                                            <h6 className="fs-15 folder-name">{hd.destinationName}</h6>
                                                        </div>
                                                        </Link>
                                                        <div className="hstack mt-4 text-muted">
                                                            <span className="me-auto"><Link className="edit-item-btn" to={"/tours/" + hd.destinationId}><i className="ri-eye-fill align-bottom text-muted" /></Link></span>
                                                            {/* <span className="me-auto"><Link className="edit-item-btn" data-bs-toggle="modal" title="Rename" data-bs-target="#createFolderModal" onClick={e => showEditDetails(hd)}><i className="ri-pencil-fill align-bottom text-muted" /></Link></span> */}
                                                            <span><Link className="remove-item-btn" title="Delete" onClick={e => onDelete(e, hd.destinationId)}><i className="ri-delete-bin-fill align-bottom text-danger" /></Link></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/*end row*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade zoomIn" id="createFolderModal" tabIndex={-1} aria-labelledby="createFolderModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0">
                        <div className="modal-header p-3 bg-soft-success">
                            <h5 className="modal-title" id="createFolderModalLabel">Folder</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" id="addFolderBtn-close" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="destinationName" className="form-label">Destination</label>
                                            <input type="text" value={values.destinationName} name="destinationName" onChange={handleInputChange} className={"form-control" + applyErrorClass('destinationName')} placeholder="Coorg, Manali..." />
                                            {errors.destinationName === false ? (<div className="validationerror">Please enter destination </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" data-bs-dismiss="modal" id="addFolderBtn-close" aria-label="Close" className="btn btn-primary w-100">{values.destinationId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" data-bs-dismiss="modal" id="addFolderBtn-close" aria-label="Close" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" data-bs-dismiss="modal" id="addFolderBtn-close" aria-label="Close" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}