import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
export default function FlightBookingDetails() {
    const params = useParams();
    const [flightBooking, setFlightBooking] = useState('');
    const [travelers, setTravelers] = useState([]);
    const [segments, setSegments] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetFlightBookingDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETFLIGHTBOOKINGBYID + "?Id=" + params.flightBookingId, { ...headerconfig })
            .then((response) => {
                if (response.data.response.data !== null) {
                    setFlightBooking(response.data.response.data);
                    setTravelers(response.data.response.data.travelers);
                    setSegments(response.data.response.data.tfSegments);
                }
            });
    };
    useEffect(() => {
        GetFlightBookingDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Flight Booking Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Journey Type</b> : {flightBooking.journeyType}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Booking No</b> : {flightBooking.bookingNo}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Supplier Booking ID</b> : {flightBooking.supplierBookingId}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>PNR</b> : {flightBooking.pnr}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Location From</b> : {flightBooking.locationFrom}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Location To</b> : {flightBooking.locationTo}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Departure Date </b> : {moment(flightBooking.departureDate).local().format('Do MMM yyyy')}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Booking Date </b> : {moment(flightBooking.bookingDate).local().format('Do MMM yyyy')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Booking Status</b> : 
                                        {flightBooking.bookingStatus === "CONFIRMED" ?
                                                                <span className="badge bg-success py-1 px-2 fw-normal text-0">Confirmed <i className="fas fa-check-circle" /></span>
                                                                : flightBooking.bookingStatus === "PENDING" ?
                                                                    <span className="badge bg-danger py-1 px-2 fw-normal text-0">PENDING <i className="fas fa-check-circle" /></span>
                                                                    : <span className="badge bg-danger py-1 px-2 fw-normal text-0">FAILED <i className="fas fa-check-circle" /></span>
                                                            }
                                        </label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Payment Status</b> : {flightBooking.paymentStatus}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Payment Gateway</b> : {flightBooking.paymentGatewayNo}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Invoice</b> : {flightBooking.invoiceNo}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Supplier Price</b> : {Math.round((flightBooking.netPrice + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Convenience Fee</b> : {Math.round((flightBooking.convenienceFee + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>GST({flightBooking.gst}%)</b> : {Math.round((flightBooking.gstAmount + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Total Price</b> : {Math.round((flightBooking.totalPrice + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                <div className="col-lg-3">
                                        <label className="form-label"><b>PaymentGatewayFee</b> : {Math.round((flightBooking.paymentGatewayFee + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>User</b> : {flightBooking.travellerName}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Email</b> : {flightBooking.travellerEmail}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Mobile</b> : {flightBooking.travellerMobile}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Itinerary</h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Departure</th>
                                                <th></th>
                                                <th>Arrival</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {segments.length > 0 && segments.map((v, index) =>
                                                <tr key={index}>
                                                    <td className="id">{moment(v.tFDepartureData.departureDateTime).format('DD MMM YYYY hh:mm a')}<br />{v.tFDepartureData.airportName}, {v.tFDepartureData.airportCode} <br />{v.tFDepartureData.city}, {v.tFDepartureData.country}</td>
                                                    <td className="id">{v.airline},({v.airlineCode}) <br /> {v.equimentType} - {v.flightNumber}<br />Duration : {getTimeFromMins(v.duration)}</td>
                                                    <td className="id">{moment(v.tFArrivalData.arrivalDateTime).format('DD MMM YYYY hh:mm a')}<br />{v.tFArrivalData.airportName}, {v.tFArrivalData.airportCode} <br />{v.tFArrivalData.city}, {v.tFArrivalData.country}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Travelers</h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Gender</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {travelers.length > 0 && travelers.map((v, index) =>
                                                <tr key={v.travelerId}>
                                                    <td>{index + 1}</td>
                                                    <td className="id">{v.title} {v.firstName} {v.lastName}</td>
                                                    <td className="id">{v.gender}</td>
                                                    <td className="id">{v.travelerType}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}