import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import Select from 'react-select';
import ReactDatePicker from "react-datepicker";
import moment from "moment";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    umrahPackageName: "",
    fromCity: "",
    toCity: "",
    totalDays: 15,
    nightsInMadina: 7,
    nightsInMakkah: 7,
    isFlightIncluded: true,
    isHotelIncluded: true,
    isAllMealsIncluded: true,
    isTransportIncluded: true,
    isZiyaratIncluded: true,
    isVisaIncluded: true,
    isDirectFlight: true,
    isBusIncluded:false,
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
    umrahFlights: [],
    umrahHotels: [],
    umrahInclusions: [],
    umrahExclusions: [],
    umrahPrices: [],
    umrahTerms: []
};
const tourFlightInitialFieldValues = {
    umrahFlightId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    airlineName: ""
};
const tourHotelInitialFieldValues = {
    umrahHotelId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    hotelName: "",
    city: ""
};
const tourPriceInitialFieldValues = {
    umrahPriceId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    adultPrice: 0,
    kidPrice: 0,
    infantPrice: 0,
    fromDate: new Date(),
    toDate: new Date()
};
const tourInclusionFieldValues = {
    umrahInclusionId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    inclusion: ""
};
const tourExclusionFieldValues = {
    umrahExclusionId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    exclusion: ""
};
const tourTermsInitialFieldValues = {
    umrahTermId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    terms: ""
};
export default function EditUmrahPackage() {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [tfValues, setTFValues] = useState(tourFlightInitialFieldValues);
    const [thValues, setTHValues] = useState(tourHotelInitialFieldValues);
    const [tpValues, setTPValues] = useState(tourPriceInitialFieldValues);
    const [tiValues, setTIValues] = useState(tourInclusionFieldValues);
    const [teValues, setTEValues] = useState(tourExclusionFieldValues);
    const [ttValues, setTTValues] = useState(tourTermsInitialFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [tourPriceState, setTourPriceState] = useState([]);
    const [tourFlightState, setTourFlightState] = useState([]);
    const [tourHotelState, setTourHotelState] = useState([]);
    const [tourTermState, setTourTermState] = useState([]);
    const [tourInclusionState, setTourInclusionState] = useState([]);
    const [tourExclusionState, setTourExclusionState] = useState([]);

    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleFlightInputChange = (e) => {
        const { name, value } = e.target;
        setTFValues({
            ...tfValues,
            [name]: value,
        });
    };
    const handleHotelInputChange = (e) => {
        const { name, value } = e.target;
        setTHValues({
            ...thValues,
            [name]: value,
        });
    };
    const handleTPInputChange = (e) => {
        const { name, value } = e.target;
        setTPValues({
            ...tpValues,
            [name]: value,
        });
    };
    const handleTIInputChange = (e) => {
        const { name, value } = e.target;
        setTIValues({
            ...tiValues,
            [name]: value,
        });
    };
    const handleTEInputChange = (e) => {
        const { name, value } = e.target;
        setTEValues({
            ...teValues,
            [name]: value,
        });
    };
    const handleTRInputChange = (e) => {
        const { name, value } = e.target;
        setTTValues({
            ...ttValues,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.umrahPackageName = values.umrahPackageName === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("umrahPackageId", values.umrahPackageId)
            formData.append("umrahPackageName", values.umrahPackageName)
            formData.append("fromCity", values.fromCity)
            formData.append("toCity", values.toCity)
            formData.append("totalDays", values.totalDays)
            formData.append("nightsInMadina", values.nightsInMadina)
            formData.append("nightsInMakkah", values.nightsInMakkah)
            formData.append("isFlightIncluded", values.isFlightIncluded)
            formData.append("isHotelIncluded", values.isHotelIncluded)
            formData.append("isAllMealsIncluded", values.isAllMealsIncluded)
            formData.append("isTransportIncluded", values.isTransportIncluded)
            formData.append("isVisaIncluded", values.isVisaIncluded)
            formData.append("isDirectFlight", values.isDirectFlight)
            formData.append("isZiyaratIncluded", values.isZiyaratIncluded)
            formData.append("isBusIncluded", values.isBusIncluded)
            formData.append("imageFile", values.imageFile)
            formData.append("imageName", values.imageName)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            update: (newrecord) => axios.put(config.APIACTIVATEURL + config.UPDATEUMRAH, newrecord, { ...headerconfig }),
            createumrahinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHINCLUSION, newrecord, { ...headerconfig }),
            updateumrahinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHINCLUSION, newrecord, { ...headerconfig }),
            deleteumrahinclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHINCLUSION, newrecord, { ...headerconfig }),
            createumrahexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHEXCLUSION, newrecord, { ...headerconfig }),
            updateumrahexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHEXCLUSION, newrecord, { ...headerconfig }),
            deleteumrahexclusion: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHEXCLUSION, newrecord, { ...headerconfig }),
            createumrahterm: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHTERM, newrecord, { ...headerconfig }),
            updateumrahterm: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHTERM, newrecord, { ...headerconfig }),
            deleteumrahterm: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHTERM, newrecord, { ...headerconfig }),
            createumrahflight: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHFLIGHTITINERARY, newrecord, { ...headerconfig }),
            updateumrahflight: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHFLIGHTITINERARY, newrecord, { ...headerconfig }),
            deleteumrahflight: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHFLIGHTITINERARY, newrecord, { ...headerconfig }),
            createumrahhotel: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHHOTELITINERARY, newrecord, { ...headerconfig }),
            updateumrahhotel: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHHOTELITINERARY, newrecord, { ...headerconfig }),
            deleteumrahhotel: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHHOTELITINERARY, newrecord, { ...headerconfig }),
            createumrahprice: (newrecord) => axios.post(config.APIACTIVATEURL + config.CREATEUMRAHPRICE, newrecord, { ...headerconfig }),
            updateumrahprice: (newrecord) => axios.post(config.APIACTIVATEURL + config.UPDATEUMRAHPRICE, newrecord, { ...headerconfig }),
            deleteumrahprice: (newrecord) => axios.post(config.APIACTIVATEURL + config.DELETEUMRAHPRICE, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .update(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Updated");
                    resetForm();
                    setBtnSubmit(true);
                }
                else {
                    handleError(res.data.message);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setTPValues(tourPriceInitialFieldValues)
        setErrors({});
    };
    // Function to add a new item to the flight
    const submitTFItem = () => {
        const formData = new FormData()
        formData.append("umrahFlightId", tfValues.umrahFlightId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("airlineName", tfValues.airlineName)
        if (tfValues.umrahFlightId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahflight(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahflight(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTFItem = (item) => {
        setTFValues(item);
    };
    const removeTFItem = (item) => {
        const formData = new FormData()
        formData.append("umrahFlightId", item.umrahFlightId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("airlineName", item.airlineName)
        applicationAPI().deleteumrahflight(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();                    
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };

    // Function to add a new item to the hotel
    const submitTHItem = () => {
        const formData = new FormData()
        formData.append("umrahHotelId", thValues.umrahHotelId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("city", thValues.city)
        formData.append("hotelName", thValues.hotelName)
        if (thValues.umrahHotelId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahhotel(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahhotel(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTHItem = (item) => {
        setTHValues(item);
    };
    const removeTHItem = (item) => {
        const formData = new FormData()
        formData.append("umrahHotelId", item.umrahHotelId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("city", item.city)
        formData.append("hotelName", item.hotelName)
        applicationAPI().deleteumrahhotel(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };

    // Function to add a new item to the itinerary
    const submitTTItem = () => {
        const formData = new FormData()
        formData.append("umrahTermId", ttValues.umrahTermId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("terms", ttValues.terms)
        if (ttValues.umrahTermId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahterm(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahterm(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTTItem = (item) => {
        setTTValues(item);
    };
    // Function to remove an item from the itinerary
    const removeTTItem = (item) => {
        const formData = new FormData()
        formData.append("umrahTermId", item.umrahTermId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("terms", item.terms)
        applicationAPI().deleteumrahterm(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    // Function to remove an item from the array inclusion
    const submitTINItem = () => {
        const formData = new FormData()
        formData.append("umrahInclusionId", tiValues.umrahInclusionId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("inclusion", tiValues.inclusion)
        if (tiValues.umrahInclusionId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahinclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahinclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const editTINItem = (item) => {
        setTIValues(item);
    };
    const removeTINItem = (item) => {
        const formData = new FormData()
        formData.append("umrahInclusionId", item.umrahInclusionId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("inclusion", item.inclusion)
        applicationAPI().deleteumrahinclusion(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    // Function to add a new item to the exclusion
    const editTEItem = (item) => {
        setTEValues(item)
    };
    const submitTEItem = () => {
        const formData = new FormData()
        formData.append("umrahExclusionId", teValues.umrahExclusionId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("exclusion", teValues.exclusion)
        if (teValues.umrahExclusionId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahexclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahexclusion(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    // Function to remove an item from the array exclusion
    const removeTEItem = (item) => {
        const formData = new FormData()
        formData.append("umrahExclusionId", item.umrahExclusionId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("exclusion", item.exclusion)
        applicationAPI().deleteumrahexclusion(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    // Function to add a new item to the price
    const editTPItem = (item) => {
        setTPValues(item)
    };
    const submitTPItem = () => {
        const formData = new FormData()
        formData.append("umrahPriceId", tpValues.umrahPriceId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("adultPrice", tpValues.adultPrice)
        formData.append("kidPrice", tpValues.kidPrice)
        formData.append("infantPrice", tpValues.infantPrice)
        formData.append("fromDate", moment(tpValues.fromDate).format('YYYY-MM-DD'))
        formData.append("toDate", moment(tpValues.toDate).format('YYYY-MM-DD'))
        if (tpValues.umrahPriceId === "00000000-0000-0000-0000-000000000000") {
            applicationAPI().createumrahprice(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Inserted");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
        else {
            applicationAPI().updateumrahprice(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetTourDetails();
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    // Function to remove an item from the array exclusion
    const removeTPItem = (item) => {
        const formData = new FormData()
        formData.append("umrahPriceId", item.umrahPriceId)
        formData.append("umrahPackageId", params.umrahPackageId)
        formData.append("adultPrice", item.adultPrice)
        formData.append("kidPrice", item.kidPrice)
        formData.append("infantPrice", item.infantPrice)
        formData.append("fromDate", item.fromDate)
        formData.append("toDate", item.toDate)
        applicationAPI().deleteumrahprice(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    handleSuccess("Record Deleted");
                    resetForm();
                    GetTourDetails();
                }
                else {
                    handleError(res.data.data);
                    setBtnSubmit(true);
                }
            })
            .catch(function (error) {
                handleError("Error in saving data");
                setBtnSubmit(true);
            });
    };
    const applyErrorClass = (field) => field in errors && errors[field] === false ? " form-control-danger" : "";
    const params = useParams();
    const GetTourDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETUMRAHBYID + "/" + params.umrahPackageId, { ...headerconfig })
            .then((response) => {
                if (response.data.statusCode == 200) {
                    setValues(response.data.data);
                    //setTour(response.data.data);
                    if (response.data.data != null) {
                        setTourFlightState(response.data.data.umrahFlights);
                        setTourHotelState(response.data.data.umrahHotels);
                        setTourInclusionState(response.data.data.umrahInclusions);
                        setTourExclusionState(response.data.data.umrahExclusions);
                        setTourTermState(response.data.data.umrahTerms);
                        setTourPriceState(response.data.data.umrahPrices);
                    }
                }
            });
    };
    useEffect(() => {
        GetTourDetails();
    }, [])

    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Create Package</h4>
                                    <div className="hstack gap-2 justify-content-end">
                                        {
                                            btnSubmit === true ?
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">{params.tourId === "00000000-0000-0000-0000-000000000000" ? "Save" : "Update"}</button>
                                                :
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="alert alert-success">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">Package Name</label>
                                                    <input type="text" value={values.umrahPackageName} name="umrahPackageName" onChange={handleInputChange} className={"form-control" + applyErrorClass('umrahPackageName')} placeholder="Amazing Dubai" />
                                                    {errors.umrahPackageName === false ? (<div className="validationerror">Please enter package name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">From City</label>
                                                    <input type="text" value={values.fromCity} name="fromCity" onChange={handleInputChange} className={"form-control" + applyErrorClass('fromCity')} placeholder="Hyderabad" />
                                                    {errors.fromCity === false ? (<div className="validationerror">Please enter fromCity </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="toCity" className="form-label">To City</label>
                                                    <input type="text" value={values.toCity} name="toCity" onChange={handleInputChange} className={"form-control" + applyErrorClass('toCity')} placeholder="Jeddah" />
                                                    {errors.toCity === false ? (<div className="validationerror">Please enter toCity </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Total Days</label>
                                                <select name="totalDays" value={values.totalDays} onChange={handleInputChange} className={"form-select" + applyErrorClass('totalDays')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.totalDays === false ? (<div className="validationerror">Please select nights </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nights" className="form-label">Days in Madina</label>
                                                <select name="nightsInMadina" value={values.nightsInMadina} onChange={handleInputChange} className={"form-select" + applyErrorClass('nightsInMadina')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.nightsInMadina === false ? (<div className="validationerror">Please select nightsInMadina </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="nightsInMakkah" className="form-label">Days In Makkah</label>
                                                <select name="nightsInMakkah" value={values.nightsInMakkah} onChange={handleInputChange} className={"form-select" + applyErrorClass('nightsInMakkah')}>
                                                    <option value={1}>1 Day</option>
                                                    <option value={2}>2 Days</option>
                                                    <option value={3}>3 Days</option>
                                                    <option value={4}>4 Days</option>
                                                    <option value={5}>5 Days</option>
                                                    <option value={6}>6 Days</option>
                                                    <option value={7}>7 Days</option>
                                                    <option value={8}>8 Days</option>
                                                    <option value={9}>9 Days</option>
                                                    <option value={10}>10 Days</option>
                                                    <option value={11}>11 Days</option>
                                                    <option value={12}>12 Days</option>
                                                    <option value={13}>13 Days</option>
                                                    <option value={14}>14 Days</option>
                                                    <option value={15}>15 Days</option>
                                                </select>
                                                {errors.nightsInMakkah === false ? (<div className="validationerror">Please select nightsInMakkah </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isFlightIncluded" className="form-label">Is Flight Included</label>
                                                    <select name="isFlightIncluded" value={values.isFlightIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isFlightIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isHotelIncluded" className="form-label">Is Hotels Included</label>
                                                    <select name="isHotelIncluded" value={values.isHotelIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isHotelIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isAllMealsIncluded" className="form-label">Is Meals Included</label>
                                                    <select name="isAllMealsIncluded" value={values.isAllMealsIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isAllMealsIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isTransportIncluded" className="form-label">Is Transport Included</label>
                                                    <select name="isTransportIncluded" value={values.isTransportIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isTransportIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isVisaIncluded" className="form-label">Is Visa Included</label>
                                                    <select name="isVisaIncluded" value={values.isVisaIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isVisaIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isZiyaratIncluded" className="form-label">Is Ziyarat Included</label>
                                                    <select name="isZiyaratIncluded" value={values.isZiyaratIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isZiyaratIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isDirectFlight" className="form-label">Is Direct Flight?</label>
                                                    <select name="isDirectFlight" value={values.isDirectFlight} onChange={handleInputChange} className={"form-select" + applyErrorClass('isDirectFlight')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="isBusIncluded" className="form-label">Is Bus?</label>
                                                    <select name="isBusIncluded" value={values.isBusIncluded} onChange={handleInputChange} className={"form-select" + applyErrorClass('isBusIncluded')}>
                                                        <option value={true}>YES</option>
                                                        <option value={false}>NO</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-flight" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Flights</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-hotel" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Hotels</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-overview" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Price Itinerary</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-account" /></span>
                                                    <span className="d-none d-sm-block">Inclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Exclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-terms" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Terms</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="arrow-flight" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Airline Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourFlightState.length > 0 && tourFlightState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.airlineName}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn pt-3" onClick={e => editTFItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTFItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={tfValues.airlineName} className="form-control" onChange={handleFlightInputChange} name="airlineName"></input>
                                                            </td>
                                                            <td>
                                                                {tfValues.umrahFlightId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTFItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTFItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-hotel" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>City</th>
                                                            <th>Hotel Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourHotelState.length > 0 && tourHotelState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.city}</td>
                                                                <td>{v.hotelName}</td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTHItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTHItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={thValues.city} className="form-control" onChange={handleHotelInputChange} name="city"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={thValues.hotelName} className="form-control" onChange={handleHotelInputChange} name="hotelName"></input>
                                                            </td>
                                                            <td>
                                                                {thValues.umrahHotelId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTHItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTHItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-overview" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>From Date</th>
                                                            <th>To Date</th>
                                                            <th>Adult Price</th>
                                                            <th>Kid Price</th>
                                                            <th>Infant Price</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourPriceState.length > 0 && tourPriceState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>
                                                                    {moment(v.fromDate).format('DD-MM-YYYY')}
                                                                </td>
                                                                <td>
                                                                    {moment(v.toDate).format('DD-MM-YYYY')}
                                                                </td>
                                                                <td>
                                                                    {v.adultPrice}
                                                                </td>
                                                                <td>
                                                                    {v.kidPrice}
                                                                </td>
                                                                <td>
                                                                    {v.infantPrice}
                                                                </td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTPItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTPItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tpValues.fromDate} dateFormat="dd/MM/yyyy" onChange={(date) => setTPValues({ ...tpValues, ['fromDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <ReactDatePicker showIcon selected={tpValues.toDate} dateFormat="dd/MM/yyyy" onChange={(date) => setTPValues({ ...tpValues, ['toDate']: date })} />
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.adultPrice} className="form-control" onChange={handleTPInputChange} name="adultPrice"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.kidPrice} className="form-control" onChange={handleTPInputChange} name="kidPrice"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={tpValues.infantPrice} className="form-control" onChange={handleTPInputChange} name="infantPrice"></input>
                                                            </td>
                                                            <td>
                                                                {tpValues.umrahPriceId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTPItem} className="btn btn-sm btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTPItem} className="btn btn-sm btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourInclusionState.length > 0 && tourInclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTINItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTINItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <textarea type="text" value={tiValues.inclusion} className="form-control" onChange={handleTIInputChange} name="inclusion"></textarea>
                                                            </td>
                                                            <td>
                                                                {tiValues.umrahInclusionId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTINItem} className="btn btn-lg btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTINItem} className="btn btn-lg btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourExclusionState.length > 0 && tourExclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTEItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTEItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <textarea type="text" value={teValues.exclusion} className="form-control" onChange={handleTEInputChange} name="exclusion"></textarea>
                                                            </td>
                                                            <td>
                                                                {teValues.umrahExclusionId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTEItem} className="btn btn-lg btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTEItem} className="btn btn-lg btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-terms" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Terms</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {tourTermState.length > 0 && tourTermState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.terms}</td>
                                                                <td>
                                                                <Link className="remove-item-btn pt-3" onClick={e => editTTItem(v)}>
                                                                        <i class="ri-pencil-line align-bottom p-3"></i>
                                                                    </Link>
                                                                    <Link className="remove-item-btn" onClick={e => removeTTItem(v)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <textarea type="text" value={ttValues.terms} className="form-control" onChange={handleTRInputChange} name="terms"></textarea>
                                                            </td>
                                                            <td>
                                                                {ttValues.umrahTermId === "00000000-0000-0000-0000-000000000000" ?
                                                                    <button onClick={submitTTItem} className="btn btn-lg btn-primary"> Add</button>
                                                                    :
                                                                    <button onClick={submitTTItem} className="btn btn-lg btn-primary"> Update</button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}