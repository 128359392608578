import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    StudyDestinationId: "00000000-0000-0000-0000-000000000000",
    destinationName: "",
    content: "",
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage
};
export default function SendWhatsApp() {
    const [customers, setCustomers] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [requestIds, setRequestIds] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.imageSrc = values.imageSrc === defaultPBImage ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            if (requestIds.length > 0) {
                setBtnSubmit(false);
                const formData = new FormData()
                formData.append("phoneNumbers", JSON.stringify(requestIds))
                formData.append("image", values.imageFile)
                fetch(config.APIACTIVATEURL + config.SENDWHATSAPP, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(data =>
                        handleSuccess("Successfully sent."),
                        setBtnSubmit(true),
                        resetForm()
                        )
                    .catch(error => console.error('Error:', error));
            }
            else {
                alert("Please select atleast one phone number")
            }
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
        setRequestIds([]);
    };
    const GetCustomers = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETCUSTOMERS, { ...headerconfig })
            .then((response) => {
                setCustomers(response.data.data);
            });
    };
    const handleCheckboxChange = (event, checkboxId) => {
        if (event.target.checked) {
            // If checkbox is checked, add its ID to the array
            setRequestIds((prevIds) => [...prevIds, checkboxId]);
        } else {
            // If checkbox is unchecked, remove its ID from the array
            setRequestIds((prevIds) =>
                prevIds.filter((id) => id !== checkboxId)
            );
        }
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetCustomers();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Send Whats App to Customers</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row mb-3">
                                    <div className="col-lg-4 col-12">
                                        <div className="picture-container">
                                            <div className="picture mb-3">
                                                <img src={values.imageSrc} className="picture-src" width="350px" height="400px" controls />
                                            </div>
                                            <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            {errors.imageSrc === false ? (<div className="validationerror">Please upload image </div>) : ('')}
                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                {
                                                    btnSubmit === true ?
                                                        <button type="submit" className="btn btn-primary w-100">Send Whats App</button>
                                                        :
                                                        <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="col-xl-12">
                                            <div className="card card-height-100">
                                                <div className="card-header align-items-center d-flex">
                                                    <h4 className="card-title mb-0 flex-grow-1">Customers</h4>
                                                </div>{/* end card header */}
                                                <div className="card-body">
                                                    <div className="table-responsive table-card">
                                                        <table className="table align-middle" id="customerTable">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Id</th>
                                                                    <th>Name</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="list form-check-all">
                                                                {customers.length > 0 && customers.map((v, index) =>
                                                                    <tr key={v.customerId}>
                                                                        <td>
                                                                            <input type="checkbox" value={v.mobile}
                                                                                checked={requestIds.includes(v.mobile)}
                                                                                onChange={(e) => handleCheckboxChange(e, v.mobile)} />
                                                                        </td>
                                                                        <td>{index + 1}</td>
                                                                        <td>{v.customerName}</td>
                                                                        <td>{v.mobile}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div> {/* .card*/}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}