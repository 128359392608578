import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
const defaultPBImage = "/assets/images/default_holiday.jpg";
export default function Packages() {
    const params = useParams();
    const [packages, setPackages] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetLastPageData = () => {
        GetHolidays(totalPages)
    }
    const GetFirstPageData = () => {
        GetHolidays("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetHolidays(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEPACKAGE + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetHolidays(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        GetHolidays(pageNumber);
                    }
                });
        }
    }
    const GetHolidays = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETTOURBYDESTINATIONID + "/" + params.destinationId, { ...headerconfig })
            .then((response) => {
                setPackages(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    useEffect(() => {
        GetHolidays(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* end page title */}
                        <div className="row">
                            {packages.length > 0 && packages.map(p =>
                                <div className="col-xxl-4 col-xl-6">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">{p.name}, {p.destinationName}</h4>
                                            <div className="flex-shrink-0">
                                                <div className="form-check form-switch form-switch-right form-switch-md">
                                                    <Link to={"/tour/"+p.tourId} className="btn btn-sm btn-primary">VIEW</Link>
                                                </div>
                                            </div>
                                        </div>{/* end card header */}
                                        <div className="card-body">
                                            <img className="img-fluid" src={p.imageSrc} alt="Card image cap" />
                                        </div>
                                        <div className="card-body">
                                            {p.description === "undefined" ? "" :
                                                <p>{p.description}</p>
                                            }
                                            <div className="live-preview">
                                                <div data-simplebar style={{ maxHeight: 215 }}>
                                                    <ul className="list-group mb-1">
                                                        <li className="list-group-item">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 ms-2">
                                                                            <h6 className="fs-14 mb-0">Dates</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <span className="text-success">{moment(p.travelDate).format('DD MMM YYYY')} - {moment(p.returnDate).format('DD MMM YYYY')}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 ms-2">
                                                                            <h6 className="fs-14 mb-0">Nights</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <span className="text-success">{p.nights} Nights {parseInt(p.nights + 1)} Days</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 ms-2">
                                                                            <h6 className="fs-14 mb-0">Type</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <span className="text-success">{p.type}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 ms-2">
                                                                            <h6 className="fs-14 mb-0">Purchase Price</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <span className="text-success">INR {p.totalPurchasePrice}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="list-group-item">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 ms-2">
                                                                            <h6 className="fs-14 mb-0">Sale Price</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-shrink-0">
                                                                    <span className="text-success">INR {p.totalSalePrice}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>{/* end card-body */}
                                    </div>{/* end card */}
                                </div>
                            )}
                            {/* end col */}
                        </div>
                        {/* end row */}
                    </div>{/* container-fluid */}
                </div>
            </div>
        </div>
    );
}