import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import ReactApexChart from "react-apexcharts";
export default function SADashboard() {
    const [counts, setCounts] = useState([]);
    const SummaryGraph = () => {
        var options = {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        console.log(1)
                    }
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [
                    'Flights', 'Holidays', 'Visas', 'Hotels'
                ],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        };
        var series = [{
            data: [250000, 150000, 100000, 320000]
        }]
        return (
            <ReactApexChart
                className="apex-charts"
                series={series}
                options={options}
                type="bar"
                height={267.7}
            />
        )
    }
    const AgentsGraph = () => {
        var options = {
            chart: {
                height: 350,
                type: 'bar',
                events: {
                    click: function (chart, w, e) {
                        console.log(1)
                    }
                },
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            xaxis: {
                categories: [
                    'Received', 'Refunds', 'Pending'
                ],
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            }
        };
        var series = [{
            data: [15, 20, 18,]
        }]
        return (
            <ReactApexChart
                className="apex-charts"
                series={series}
                options={options}
                type="bar"
                height={267.7}
            />
        )
    }
    const DayWiseSales = () => {
        var options = {
            chart: {
                id: 'DayWiseSales',
                toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                    export: {
                        csv: {
                            filename: undefined,
                            columnDelimiter: ',',
                            headerCategory: 'Date and Time',
                            headerValue: 'Usage',
                            dateFormatter(timestamp) {
                                return new Date(timestamp).toDateString()
                            }
                        },
                        png: {
                            filename: "Water-Usage-Daily",
                        }
                    },
                    autoSelected: 'zoom'
                },
            },
            xaxis: {
                categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17"]
            }
        };
        var series = [{
            name: 'Sales',
            data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 45, 15, 78, 25, 56, 89, 44, 26]
        }]
        return (
            <ReactApexChart
                className="apex-charts"
                series={series}
                options={options}
                type="bar"
                height={267.7}
            />
        )
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const GetCounts = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETCOUNTS, { ...headerconfig })
            .then((response) => {
                setCounts(response.data);
            });
    };
    useEffect(() => {
        //GetCounts();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Dashboard</h4>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}
                        <div className="row mb-3 pb-1">
                            <div className="col-12">
                                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                    <div className="flex-grow-1">
                                        <h4 className="fs-16 mb-1">Good Morning,</h4>
                                        <p className="text-muted mb-0">Here's what's happening today.</p>
                                    </div>
                                    <div className="mt-3 mt-lg-0">
                                        <form action="javascript:void(0);">
                                            <div className="row g-3 mb-0 align-items-center">
                                                {/*end col*/}
                                                {/* <div className="col-auto">
                                                    <Link to={"/holidayenquiry"} className="btn btn-success"><i className="ri-add-circle-line align-middle me-1" />
                                                        New Holiday Enquiry</Link>
                                                </div>
                                                <div className="col-auto">
                                                    <Link to={"/Studyenquiry"} className="btn btn-info"><i className="ri-add-circle-line align-middle me-1" />
                                                        New Study Request</Link>
                                                </div> */}
                                                {/*end col*/}
                                            </div>
                                            {/*end row*/}
                                        </form>
                                    </div>
                                </div>{/* end card header */}
                            </div>
                            {/*end col*/}
                        </div>
                        <div className="row row-cols-xxl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            <div className="col">
                                <div className="card">
                                    <div className="card-body d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{counts.flightBookings}</h4>
                                            <h6 className="text-muted mb-0">Flight Bookings</h6>
                                        </div>
                                        <div className="flex-shrink-0 avatar-sm">
                                            <div className="avatar-title bg-warning fs-22 rounded">
                                                <i className="ri-flight-takeoff-line" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{counts.flightCancellations}</h4>
                                            <h6 className="text-muted mb-0">Flight Cancellations</h6>
                                        </div>
                                        <div className="flex-shrink-0 avatar-sm">
                                            <div className="avatar-title bg-danger fs-22 rounded">
                                                <i className="ri-flight-takeoff-line" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{counts.visaRequests}</h4>
                                            <h6 className="text-muted mb-0">Hotel Bookings</h6>
                                        </div>
                                        <div className="flex-shrink-0 avatar-sm">
                                            <div className="avatar-title bg-soft-info text-info fs-22 rounded">
                                                <i className="ri-book-2-fill" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{counts.suppliers}</h4>
                                            <h6 className="text-muted mb-0">Suppliers</h6>
                                        </div>
                                        <div className="flex-shrink-0 avatar-sm">
                                            <div className="avatar-title bg-soft-danger text-danger fs-22 rounded">
                                                <i className="ri-user-line" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                            <div className="col">
                                <div className="card">
                                    <div className="card-body d-flex">
                                        <div className="flex-grow-1">
                                            <h4>{counts.leads}</h4>
                                            <h6 className="text-muted fs-13 mb-0">Customers</h6>
                                        </div>
                                        <div className="flex-shrink-0 avatar-sm">
                                            <div className="avatar-title bg-soft-primary text-primary fs-22 rounded">
                                                <i className="ri-user-line" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*end col*/}
                        </div>
                        <div className='row'>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header border-0 align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Bookings and Enquiries Summary</h4>
                                    </div>
                                    <div className="card-body">
                                        <SummaryGraph type="bar" height={350} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-header border-0 align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Payments Summary</h4>
                                    </div>
                                    <div className="card-body">
                                        <AgentsGraph type="bar" height={350} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header border-0 align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Day Wise Sales</h4>
                                    </div>
                                    <div className="card-body">
                                        <DayWiseSales type="bar" id="Market_chart" data-colors="[&quot;--vz-success&quot;, &quot;--vz-danger&quot;]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* container-fluid */}
                </div>
                {/* End Page-content */}
            </div>
        </div>
    );
}