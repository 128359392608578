import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    packageId: "00000000-0000-0000-0000-000000000000",
    holidayDestinationId: "00000000-0000-0000-0000-000000000000",
    tour: "DOMESTIC",
    packageName: "",
    description: "",
    packageurl: "",
    accomdation: 2,
    noOfNights: "2",
    tourType: "0",
    status: "ACTIVE",
    isFlight: "YES",
    supplierPrice: 0,
    salePrice: 0,
    pricePerPersons: "0",
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage,
    holidayItineraries: [],
    packageInclusion: [],
    packageExclusion: []
};
const holidayInitialFieldValues = {
    title: "",
    description: ""
};
const packageInclusionFieldValues = {
    inclusion: ""
};
const packageExclusionFieldValues = {
    exclusion: ""
};
export default function PackageDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [holidayDestination, setHolidayDestination] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [holidayValues, setHolidayValues] = useState(holidayInitialFieldValues);
    const [piValues, setPIValues] = useState(packageInclusionFieldValues);
    const [peValues, setPEValues] = useState(packageExclusionFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [holidayItineraryState, setHolidayItineraryState] = useState([])
    const [packageInclusionState, setPackageInclusionState] = useState([]);
    const [packageExclusionState, setPackageExclusionState] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        let val = parseInt(e.target.value, 10);
        if (isNaN(val)) {
            setValues({
                ...values,
                [name]: "",
            });
        } else {
            val = val >= 0 ? val : 0;
            setValues({
                ...values,
                [name]: val,
            });
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleHolidayInputChange = (e) => {
        const { name, value } = e.target;
        setHolidayValues({
            ...holidayValues,
            [name]: value,
        });
    };
    const handlePIInputChange = (e) => {
        const { name, value } = e.target;
        setPIValues({
            ...piValues,
            [name]: value,
        });
    };
    const handlePEInputChange = (e) => {
        const { name, value } = e.target;
        setPEValues({
            ...peValues,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.packageName = values.packageName === "" ? false : true;
        temp.noOfNights = values.noOfNights === "0" ? false : true;
        temp.salePrice = values.salePrice === 0 ? false : true;
        temp.perPersons = values.perPersons === "0" ? false : true;
        temp.tourType = values.tourType === "0" ? false : true;
        temp.holidayDestinationId = values.holidayDestinationId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            console.log(holidayItineraryState)
            const formData = new FormData()
            formData.append("packageId", values.packageId)
            formData.append("holidayDestinationId", values.holidayDestinationId)
            formData.append("packageName", values.packageName)
            formData.append("noOfNights", values.noOfNights)
            formData.append("tourType", values.tourType)
            formData.append("supplierPrice", values.supplierPrice)
            formData.append("salePrice", values.salePrice)
            formData.append("pricePerPersons", values.pricePerPersons)
            formData.append("status", values.status)
            formData.append("tour", values.tour)
            formData.append("isFlight", values.isFlight)
            formData.append("accomdation", values.accomdation)
            formData.append("description", values.description)
            formData.append("imageName", values.imageName)
            formData.append("imageFile", values.imageFile)
            //formData.append("holidayItineraries", JSON.stringify(holidayItineraryState))
            //formData.append("packageInclusion", JSON.stringify(packageInclusionState))
            //formData.append("packageExclusion", JSON.stringify(packageExclusionState))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEPACKAGE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('packageId') !== "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        setBtnSubmit(true);
                        navigate('/packages')
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setHolidayValues(holidayInitialFieldValues)
        setErrors({});
    };
    const GetHolidayDestinations = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLHOLIDAYDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setHolidayDestination(response.data.data);
            });
    };
    // Function to add a new item to the array
    const addHIItem = (index) => {
        const newItem = { id: holidayItineraryState.length + 1, title: holidayValues.title, description: holidayValues.description };
        console.log(newItem)
        setHolidayItineraryState([...holidayItineraryState, newItem]);
        setHolidayValues(holidayInitialFieldValues)
    };
    // Function to remove an item from the array by ID
    const removeHIItem = (id) => {
        console.log(id)
        const updatedItems = holidayItineraryState.filter(item => item.id !== id);
        setHolidayItineraryState(updatedItems);
    };

    // Function to add a new item to the array
    const addPIItem = () => {
        const newItem = { id: packageInclusionState.length + 1, inclusion: piValues.inclusion };
        console.log(newItem)
        setPackageInclusionState([...packageInclusionState, newItem]);
        setPIValues(packageInclusionFieldValues)
    };
    // Function to remove an item from the array by ID
    const removePIItem = (id) => {
        console.log(id)
        const updatedItems = packageInclusionState.filter(item => item.id !== id);
        setPackageInclusionState(updatedItems);
    };
    // Function to add a new item to the array
    const addPEItem = () => {
        const newItem = { id: packageExclusionState.length + 1, exclusion: peValues.exclusion };
        console.log(newItem)
        setPackageExclusionState([...packageExclusionState, newItem]);
        setPEValues(packageExclusionFieldValues)
    };
    // Function to remove an item from the array by ID
    const removePEItem = (id) => {
        console.log(id)
        const updatedItems = packageInclusionState.filter(item => item.id !== id);
        setPackageExclusionState(updatedItems);
    };
    const GetHolidayItineraries = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETHOLIDAYITINERARYBYPACKAGEID + "?PackageId=" + params.packageId + "", { ...headerconfig })
            .then((response) => {
                setValues(response.data.data);
                setHolidayItineraryState(response.data.data.holidayItineraries);
                setPackageExclusionState(response.data.data.packageExclusions);
                setPackageInclusionState(response.data.data.packageInclusions)
            });
    };
    const applyErrorClass = (field) => field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetHolidayDestinations();
        //GetPackageDetails();
        GetHolidayItineraries();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Modify Package</h4>
                                    <div className="hstack gap-2 justify-content-end">
                                        {
                                            btnSubmit === true ?
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100">{values.packageId === "00000000-0000-0000-0000-000000000000" ? "Save" : "Update"}</button>
                                                :
                                                <button type="button" onClick={handleSubmit} className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                        <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="alert alert-success">
                                    <div className="row mb-3">
                                        <div className="col-sm-3 col-12">
                                            <div className="picture-container">
                                                <div className="picture mb-3">
                                                    <img src={values.imageSrc} className="picture-src" width="250px" height="250px" controls />
                                                </div>
                                                <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Destination</label>
                                                    <select name="holidayDestinationId" value={values.holidayDestinationId} onChange={handleInputChange} className={"form-select" + applyErrorClass('holidayDestinationId')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        {holidayDestination.map(o => (
                                                            <option key={o.holidayDestinationId} value={o.holidayDestinationId}>{o.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.holidayDestinationId === false ? (<div className="validationerror">Please select destination </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="packageName" className="form-label">Package Name</label>
                                                    <input type="text" value={values.packageName} name="packageName" onChange={handleInputChange} className={"form-control" + applyErrorClass('packageName')} placeholder="Amazing Dubai" />
                                                    {errors.packageName === false ? (<div className="validationerror">Please enter package name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="tour" className="form-label">Tour</label>
                                                <select name="tour" value={values.tour} onChange={handleInputChange} className={"form-select" + applyErrorClass('tour')}>
                                                    <option value="DOMESTIC">DOMESTIC</option>
                                                    <option value="INTERNATIONAL">INTERNATIONAL</option>
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="tourType" className="form-label">Tour Type</label>
                                                    <select name="tourType" value={values.tourType} onChange={handleInputChange} className={"form-select" + applyErrorClass('tourType')}>
                                                        <option value="0">Please Select</option>
                                                        <option value="HONEYMOON">Honeymoon</option>
                                                        <option value="FIXED">Fixed Tour</option>
                                                        <option value="FAMILY">Family Tour</option>
                                                        <option value="GROUP">Group Tour</option>
                                                        <option value="DAILY">Daily Tour</option>
                                                        <option value="CORPORATE">Corporate</option>
                                                    </select>
                                                    {errors.tourType === false ? (<div className="validationerror">Please select tourType </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="noOfNights" className="form-label">Nights</label>
                                                <select name="noOfNights" value={values.noOfNights} onChange={handleInputChange} className={"form-select" + applyErrorClass('noOfNights')}>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                                {errors.noOfNights === false ? (<div className="validationerror">Please select nights </div>) : ('')}
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="accomdation" className="form-label">Accomdation</label>
                                                <select name="accomdation" value={values.accomdation} onChange={handleInputChange} className={"form-select" + applyErrorClass('accomdation')}>
                                                    <option value="2">2 Star</option>
                                                    <option value="3">3 Star</option>
                                                    <option value="4">4 Star</option>
                                                    <option value="5">5 Star</option>
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <label htmlFor="isFlight" className="form-label">Is Flight</label>
                                                <select name="isFlight" value={values.isFlight} onChange={handleInputChange} className={"form-select" + applyErrorClass('isFlight')}>
                                                    <option value="YES">YES</option>
                                                    <option value="NO">NO</option>
                                                </select>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="netPrice" className="form-label">Supplier Price</label>
                                                    <input type="number" min={0} value={values.supplierPrice} name="supplierPrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('supplierPrice')} placeholder="0" />
                                                    {errors.supplierPrice === false ? (<div className="validationerror">Please enter supplier price </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="netPrice" className="form-label">Sale Price</label>
                                                    <input type="number" min={0} value={values.salePrice} name="salePrice" onChange={handleNumberChange} className={"form-control" + applyErrorClass('salePrice')} placeholder="0" />
                                                    {errors.salePrice === false ? (<div className="validationerror">Please enter salePrice </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="pricePerPersons" className="form-label">Price Per Person(s)</label>
                                                    <select name="pricePerPersons" value={values.pricePerPersons} onChange={handleInputChange} className={"form-select" + applyErrorClass('pricePerPersons')}>
                                                        <option value="0">Please Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                    {errors.perPersons === false ? (<div className="validationerror">Please select persons </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Status</label>
                                                    <select name="status" value={values.status} onChange={handleInputChange} className={"form-select" + applyErrorClass('status')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        <option value="ACTIVE">ACTIVE</option>
                                                        <option value="INACTIVE">INACTIVE</option>
                                                    </select>
                                                    {errors.status === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label htmlFor="description" className="form-label">Description</label>
                                                    <input type="text" value={values.description} name="description" onChange={handleInputChange} className={"form-control" + applyErrorClass('description')} />
                                                    {errors.description === false ? (<div className="validationerror">Please enter description </div>) : ('')}
                                                </div>
                                            </div>

                                            <div className="col-lg-3">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ul className="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-bs-toggle="tab" href="#arrow-overview" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-home-variant" /></span>
                                                    <span className="d-none d-sm-block">Holiday Itinerary</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-profile" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-account" /></span>
                                                    <span className="d-none d-sm-block">Inclusions</span>
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-bs-toggle="tab" href="#arrow-contact" role="tab">
                                                    <span className="d-block d-sm-none"><i className="mdi mdi-email" /></span>
                                                    <span className="d-none d-sm-block">Exclusions</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {/* Tab panes */}
                                        <div className="tab-content text-muted">
                                            <div className="tab-pane active" id="arrow-overview" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Description</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {holidayItineraryState.length > 0 && holidayItineraryState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.title}</td>
                                                                <td>{v.description}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removeHIItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={holidayValues.title} className="form-control" onChange={handleHolidayInputChange} name="title"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={holidayValues.description} className="form-control" onChange={handleHolidayInputChange} name="description"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addHIItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-profile" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Inclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {packageInclusionState.length > 0 && packageInclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.inclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removePIItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={piValues.inclusion} className="form-control" onChange={handlePIInputChange} name="inclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addPIItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div className="tab-pane" id="arrow-contact" role="tabpanel">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Exclusions</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {packageExclusionState.length > 0 && packageExclusionState.map((v, index) =>
                                                            <tr key={index}>
                                                                <td>{v.exclusion}</td>
                                                                <td>
                                                                    <Link className="remove-item-btn" onClick={e => removePEItem(index + 1)}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <input type="text" value={peValues.exclusion} className="form-control" onChange={handlePEInputChange} name="exclusion"></input>
                                                            </td>
                                                            <td>
                                                                <button onClick={addPEItem} className="btn btn-sm btn-primary"> Add</button>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>{/* end card-body */}
                                </div>{/* end card */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}