import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";

const initialFieldValues = {
    umrahEnquiryId: "00000000-0000-0000-0000-000000000000",
    umrahPackageId: "00000000-0000-0000-0000-000000000000",
    umrahPriceId: "00000000-0000-0000-0000-000000000000",
    adults: "",
    kids: "",
    infants: "",
    name: "",
    email: "",
    mobile: "",
    status: "",
};
export default function UmrahEnquiry() {
    const [enquiry, setEnquiry] = useState([]);
    const [packages, setPackages] = useState([]);
    const [prices, setPrices] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        temp.umrahPackageId = values.umrahPackageId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.umrahPriceId = values.umrahPriceId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.name = values.name === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("umrahEnquiryId", values.umrahEnquiryId)
            formData.append("umrahPackageId", values.umrahPackageId)
            formData.append("umrahPriceId", values.umrahPriceId)
            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("status", values.status)
            formData.append("comments", values.comments)
            formData.append("adults", values.adults)
            formData.append("kids", values.kids)
            formData.append("infants", values.infants)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEUMRAHENQUIRY, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEUMRAHENQUIRY, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('umrahEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetEnquiry(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetEnquiry(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetEnquiry(totalPages)
    }
    const GetFirstPageData = () => {
        GetEnquiry("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetEnquiry(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEUMRAHENQUIRY + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetEnquiry(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        GetEnquiry(pageNumber);
                    }
                });
        }
    }
    const GetEnquiry = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETUMRAHENQUIRYS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                if (response.data.data.data !== null) {
                    setEnquiry(response.data.data.data);
                    setPageNumber(response.data.data.pageNumber);
                    setPageSize(response.data.data.pageSize);
                    setTotalPages(response.data.data.totalPages);
                    setData(response.data.data.data);
                    setTotalRecords(response.data.data.totalRecords);
                }
            });
    };
    const GetUmrahPackages = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETUMRAHS + "?pageNumber=1&pageSize=30", { ...headerconfig })
            .then((response) => {
                setPackages(response.data.data);
            });
    };
    const GetUmrahPrices = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETUMRAHPRICES + "?UmrahPackageId=" + values.umrahPackageId + "", { ...headerconfig })
            .then((response) => {
                setPrices(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetEnquiry(pageNumber);
        GetUmrahPackages();
        GetUmrahPrices();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Umrah Enquiries</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="agencyName" className="form-label">Package Name</label>
                                            <select name="umrahPackageId" value={values.umrahPackageId} onChange={handleInputChange} className={"form-select" + applyErrorClass('umrahPackageId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please select</option>
                                                {packages.length > 0 && packages.map(p =>
                                                    <option value={p.umrahPackageId}>{p.umrahPackageName}</option>
                                                )}
                                            </select>
                                            {errors.umrahPackageId === false ? (<div className="validationerror">Please select package </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="agencyName" className="form-label">Dates</label>
                                            <select name="umrahPriceId" value={values.umrahPriceId} onChange={handleInputChange} className={"form-select" + applyErrorClass('umrahPriceId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please select</option>
                                                {prices.length > 0 && prices.map(p =>
                                                    <option value={p.umrahPriceId}>{moment(p.fromDate).format('DD MMM YYYY')} - {moment(p.toDate).format('DD MMM YYYY')}</option>
                                                )}
                                            </select>
                                            {errors.umrahPriceId === false ? (<div className="validationerror">Please select dates </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Please Enter Name" />
                                            {errors.name === false ? (<div className="validationerror">Please enter name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please Enter Email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="mobile" className="form-label">Mobile</label>
                                            <input type="text" max={12} value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Please Enter Mobile" />
                                            {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">Adults</label>
                                            <input type="text" value={values.adults} name="adults" onChange={handleInputChange} className={"form-control" + applyErrorClass('adults')} placeholder="Please Enter adults" />
                                            {errors.adults === false ? (<div className="validationerror">Please enter adults </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">Kids</label>
                                            <input type="text" value={values.kids} name="kids" onChange={handleInputChange} className={"form-control" + applyErrorClass('kids')} placeholder="Please Enter kids" />
                                            {errors.kids === false ? (<div className="validationerror">Please enter kids </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label">Status</label>
                                            <select name="status" value={values.status} onChange={handleInputChange} className={"form-select" + applyErrorClass('status')}>
                                                <option value="NEW">NEW</option>
                                                <option value="REPLIED">REPLIED</option>
                                                <option value="CONFIRMED">CONFIRMED</option>
                                                <option value="CANCELLED">CANCELLED</option>
                                            </select>
                                            {errors.status === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="comments" className="form-label">Comments</label>
                                            <input type="text" value={values.comments} name="comments" onChange={handleInputChange} className={"form-control" + applyErrorClass('comments')} placeholder="Please Enter comments" />
                                            {errors.comments === false ? (<div className="validationerror">Please enter comments </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary">{values.umrahEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Enquiries</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {enquiry.length > 0 && enquiry.map((v, index) =>
                                                        <tr key={v.umrahEnquiryId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.name}</td>
                                                            <td>{v.email}</td>
                                                            <td>{v.mobile}</td>
                                                            <td>{v.status}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                        <Link className="edit-item-btn" to={"/umrahenquiry/" + v.umrahEnquiryId}><i className="ri-eye-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{enquiry.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}