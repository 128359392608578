import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import ReactSelect from "react-select";
const initialFieldValues = {
    packageEnquiryId: "00000000-0000-0000-0000-000000000000",
    destinationId: "00000000-0000-0000-0000-000000000000",
    customerId: "00000000-0000-0000-0000-000000000000",
    customerName: "",
    email: "",
    mobile: "",
    status: "NEW",
    travelDate: new Date(),
    returnDate: new Date(),
    travellers: 0,
    nights: 0,
    tourType: "Honeymoon",
    category: 2,
    notes: "",
    flight: "on",
    hotel: "on",
    transfers: "on",
    sightseeing: "on",
    visa: "on",
    tourType: "FAMILY"
};
export default function Enquiry() {
    const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
    const [tours, setTours] = useState([]);
    const [holidayDestinations, setHolidayDestinations] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);
    const addDays = (cindate) => {
        console.log(cindate)
        console.log(toDate)
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.customerName = values.customerName === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        temp.nights = values.nights === 0 ? false : true;
        temp.holidayDestinationId = values.holidayDestinationId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("packageEnquiryId", values.packageEnquiryId)
            formData.append("destinationId", values.destinationId)
            formData.append("customerName", values.customerName)
            formData.append("customerId", values.customerId)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("travelDate", moment(values.travelDate).format('YYYY-MM-DD'))
            formData.append("returnDate", moment(values.returnDate).format('YYYY-MM-DD'))
            formData.append("nights", values.nights)
            formData.append("travellers", values.travellers)
            formData.append("category", values.category)
            formData.append("flight", values.flight)
            formData.append("hotel", values.hotel)
            formData.append("sightseeing", values.sightseeing)
            formData.append("visa", values.visa)
            formData.append("transfers", values.transfers)
            formData.append("notes", values.notes)
            formData.append("tourType", values.tourType)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEPACKAGEENQUIRY, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEPACKAGEENQUIRY, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('packageEnquiryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GETPACKAGEENQUIRYS(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GETPACKAGEENQUIRYS(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setModal(!modal);
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GETPACKAGEENQUIRYS(totalPages)
    }
    const GetFirstPageData = () => {
        GETPACKAGEENQUIRYS("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
        GETPACKAGEENQUIRYS(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEPACKAGEENQUIRY + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GETPACKAGEENQUIRYS(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        GETPACKAGEENQUIRYS(pageNumber);
                    }
                });
        }
    }
    const GetHolidayDestinations = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLDESTINATIONS, { ...headerconfig })
            .then((response) => {
                setHolidayDestinations(response.data.data);
            });
    };
    const GETPACKAGEENQUIRYS = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETPACKAGEENQUIRYS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setTours(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetHolidayDestinations();
        GETPACKAGEENQUIRYS(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Tour Enquiries</h4>
                                </div>
                            </div>
                        </div>
                        <Modal isOpen={modal} toggle={toggle} size="lg">
                            <ModalHeader toggle={toggle}>Enquiry Details</ModalHeader>
                            <ModalBody>
                                <div className="alert alert-success">
                                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Destination</label>
                                                    <ReactSelect options={holidayDestinations} onChange={e=>setValues({...values,['destinationId']: e.value})}></ReactSelect>
                                                    {errors.holidayDestinationId === false ? (<div className="validationerror">Please select destination </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Travel Date</label>
                                                    <div className="input-group">
                                                        <ReactDatePicker className="form-control" wrapperClassName="datePicker" showIcon name="travelDate" selected={values.travelDate} onChange={(date) => setValues({ ...values, ['travelDate']: date })} dateFormat="EEE dd MMM yyyy" minDate={new Date()} />
                                                    </div>
                                                    {errors.travelDate === false ? (<div className="validationerror">Please select travelDate </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="status" className="form-label">Return Date</label>
                                                    <div className="input-group">
                                                        <ReactDatePicker showIcon className="form-control" wrapperClassName="datePicker" name="returnDate" selected={values.returnDate} onChange={(date) => setValues({ ...values, ['returnDate']: date })} dateFormat="EEE dd MMM yyyy" minDate={new Date()} />
                                                    </div>
                                                    {errors.returnDate === false ? (<div className="validationerror">Please select returnDate </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="customerName" className="form-label">Customer Name *</label>
                                                    <input type="text" value={values.customerName} name="customerName" onChange={handleInputChange} className={"form-control" + applyErrorClass('customerName')} placeholder="Please enter customer name" />
                                                    {errors.customerName === false ? (<div className="validationerror">Please enter customer name </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="mobile" className="form-label">Mobile *</label>
                                                    <input type="text" value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please enter mobile" />
                                                    {errors.mobile === false ? (<div className="validationerror">Please enter mobile </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                                    {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="nights" className="form-label">Nights*</label>
                                                    <input min={1} type="number" className={"form-control" + applyErrorClass('nights')} value={values.nights} name="nights" onChange={handleInputChange}></input>
                                                    {errors.nights === false ? (<div className="validationerror">Nights </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="travellers" className="form-label">Travellers*</label>
                                                    <input min={1} type="number" className={"form-control" + applyErrorClass('travellers')} value={values.travellers} name="travellers" onChange={handleInputChange}></input>
                                                    {errors.travellers === false ? (<div className="validationerror">Please select travellers </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="category" className="form-label">Hotel Category</label>
                                                    <select name="category" value={values.category} onChange={handleInputChange} className={"form-select" + applyErrorClass('category')}>
                                                        <option value={1}>2 Star</option>
                                                        <option value={3}>3 Star</option>
                                                        <option value={4}>4 Star</option>
                                                        <option value={5}>5 Star</option>
                                                        <option value={6}>6 Star</option>
                                                        <option value={7}>7 Star</option>
                                                    </select>
                                                    {errors.category === false ? (<div className="validationerror">Please select category </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label className="form-label" for="formCheck2">
                                                        Flights
                                                    </label>
                                                    <div className="input-group">
                                                        <input class="form-check-input" name="flight" type="checkbox" defaultChecked={values.flight} onClick={handleInputChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label className="form-label" for="formCheck2">
                                                        Hotels
                                                    </label>
                                                    <div className="input-group">
                                                        <input class="form-check-input" name="hotel" type="checkbox" defaultChecked={values.hotel} onClick={handleInputChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label className="form-label" for="formCheck2">
                                                        Visas
                                                    </label>
                                                    <div className="input-group">
                                                        <input class="form-check-input" name="visa" type="checkbox" defaultChecked={values.visa} onClick={handleInputChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label className="form-label" for="formCheck2">
                                                        Transfers
                                                    </label>
                                                    <div className="input-group">
                                                        <input class="form-check-input" name="transfers" type="checkbox" defaultChecked={values.transfers} onClick={handleInputChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1">
                                                <div className="mb-3">
                                                    <label className="form-label" for="formCheck2">
                                                        Sightseeing
                                                    </label>
                                                    <div className="input-group">
                                                        <input class="form-check-input" name="sightseeing" type="checkbox" defaultChecked={values.sightseeing} onClick={handleInputChange}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="mb-3">
                                                    <label htmlFor="tourType" className="form-label">Tour Type</label>
                                                    <select name="tourType" value={values.tourType} onChange={handleInputChange} className={"form-select" + applyErrorClass('tourType')}>
                                                        <option value="FAMILY">FAMILY</option>
                                                        <option value="CORPORATE">CORPORATE</option>
                                                        <option value="HONEYMOON">HONEYMOON</option>
                                                        <option value="OTHERS">OTHERS</option>
                                                    </select>
                                                    {errors.category === false ? (<div className="validationerror">Please select category </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="mb-3">
                                                    <label htmlFor="notes" className="form-label">Notes</label>
                                                    <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Beach side villa, Veg Food..." />
                                                    {errors.notes === false ? (<div className="validationerror">Please enter tour requirements </div>) : ('')}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                    {
                                                        btnSubmit === true ?
                                                            <button type="submit" className="btn btn-primary w-100">{values.packageEnquiryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                            :
                                                            <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                                    }
                                                    <button type="button" className="btn btn-danger w-100" onClick={toggle}>Close</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ModalBody>
                        </Modal>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Enquiries</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Destination</th>
                                                        <th>Travel Date</th>
                                                        <th>Travellers</th>
                                                        <th>Nights</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Type</th>
                                                        <th>Status</th>
                                                        <th>Last Updated</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {tours.length > 0 && tours.map((v, index) =>
                                                        <tr key={v.packageEnquiryId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.destinationName}</td>
                                                            <td>{moment(v.travelDate).format('DD MMM YYYY')}</td>
                                                            <td>{v.travellers}</td>
                                                            <td>{v.nights}</td>
                                                            <td>{v.customerName}</td>
                                                            <td>{v.mobile}</td>
                                                            <td>{v.tourType}</td>
                                                            <td>{v.status}</td>
                                                            <td>{moment(v.updatedDate).format('DD-MM-YYYY hh:mm a')}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, v.packageEnquiryId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{tours.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}