import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";

const initialFieldValues = {
    holidayItineraryId: "00000000-0000-0000-0000-000000000000",
    packageId: "00000000-0000-0000-0000-000000000000",
    title: "",
    description: ""
};
export default function HolidayItinerary() {
    const [holidayItineraries, setHolidayItineraries] = useState([]);
    const [packages, setPackages] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleHolidayChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
        GetHolidayItineraries(e.target.value, pageNumber);
    };
    const validate = () => {
        let temp = {};
        temp.title = values.title === "" ? false : true;
        temp.description = values.description === "" ? false : true;
        temp.packageId = values.packageId === "00000000-0000-0000-0000-000000000000" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("holidayItineraryId", values.holidayItineraryId)
            formData.append("packageId", values.packageId)
            formData.append("title", values.title)
            formData.append("description", values.description)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEHOLIDAYITINERARY, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEHOLIDAYITINERARY, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('holidayItineraryId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetHolidayItineraries(values.packageId, pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetHolidayItineraries(values.packageId, pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetHolidayItineraries(values.packageId, totalPages);
    }
    const GetFirstPageData = () => {
        GetHolidayItineraries(values.packageId, "1");
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetHolidayItineraries(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id, pid) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEHOLIDAYITINERARY + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetHolidayItineraries(pid, pageNumber);
                        resetForm();
                    }
                    else {
                        handleError(response.data.data);
                        GetHolidayItineraries(pid, pageNumber);
                        resetForm();                        
                    }
                });
        }
    }
    const GetPackages = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLPACKAGES, { ...headerconfig })
            .then((response) => {
                setPackages(response.data.data);
            });
    };
    const GetHolidayItineraries = (packageId, number) => {
        if (packageId !== "00000000-0000-0000-0000-000000000000") {
            axios
                .get(config.APIACTIVATEURL + config.GETHOLIDAYITINERARYBYPACKAGEID + "?PackageId=" + packageId + "", { ...headerconfig })
                .then((response) => {
                    setHolidayItineraries(response.data.data.holidayItineraries);
                });
        }
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        //GetHolidayItineraries(values.packageId,pageNumber);
        GetPackages(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Holiday Itinerary</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label">Packages</label>
                                            <select name="packageId" value={values.packageId} onChange={handleHolidayChange} className={"form-select" + applyErrorClass('packageId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                {packages.map(o => (
                                                    <option key={o.packageId} value={o.packageId}>{o.packageName}</option>
                                                ))}
                                            </select>
                                            {errors.packageId === false ? (<div className="validationerror">Please select package </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="mb-3">
                                            <label htmlFor="title" className="form-label">Title</label>
                                            <input type="text" value={values.title} name="title" onChange={handleInputChange} className={"form-control" + applyErrorClass('title')} placeholder="Please Enter Title" />
                                            {errors.title === false ? (<div className="validationerror">Please enter title </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <input type="text" value={values.description} name="description" onChange={handleInputChange} className={"form-control" + applyErrorClass('description')} placeholder="Please Enter Description" />
                                            {errors.description === false ? (<div className="validationerror">Please enter description </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary w-100">{values.holidayItineraryId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Holiday Itinerary</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {holidayItineraries.length > 0 && holidayItineraries.map((v, index) =>
                                                        <tr key={v.holidayItineraryId}>
                                                            <td>{index + 1}</td>
                                                            <td>{v.title}</td>
                                                            <td>{v.description}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, v.holidayItineraryId, v.packageId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}