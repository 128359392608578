import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";

const initialFieldValues = {
    companyId: "00000000-0000-0000-0000-000000000000",
    companyName: "",
    gstNo: "",
    email: "",
    mobile: "",
    tcs: 0,
    hotelGST: 0,
    flightGST: 0,
    flightCommission: 0,
    hotelCommission: 0,
    paymentGateway:0
};
export default function Company() {
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
            "Content-Type":"application/json"
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        temp.companyName = values.companyName === "" ? false : true;
        temp.gstNo = values.gstNo === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.mobile = values.mobile === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {        
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("companyId", values.companyId)
            formData.append("companyName", values.companyName)
            formData.append("gstNo", values.gstNo)
            formData.append("email", values.email)
            formData.append("mobile", values.mobile)
            formData.append("tcs", values.tcs)
            formData.append("hotelGST", values.hotelGST)
            formData.append("flightGST", values.flightGST)
            formData.append("flightCommission", values.flightCommission)
            formData.append("hotelCommission", values.hotelCommission)
            formData.append("paymentGateway", values.paymentGateway)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            update: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.UPDATECOMPANY, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.response.succeeded === true) {
                        handleSuccess("Record Updated");
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetCompany = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETCOMPANY, { ...headerconfig })
            .then((response) => {
                if (response.data.response.data !== null) {
                setValues(response.data.response.data);
                }
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetCompany();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Company Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">Company Name</label>
                                            <input type="text" value={values.companyName} name="companyName" onChange={handleInputChange} className={"form-control" + applyErrorClass('brandName')} placeholder="Please Enter Company Name" />
                                            {errors.companyName === false ? (<div className="validationerror">Please enter company name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">GST No</label>
                                            <input type="text" value={values.gstNo} name="gstNo" onChange={handleInputChange} className={"form-control" + applyErrorClass('gstNo')} placeholder="Please Enter gstNo" />
                                            {errors.gstNo === false ? (<div className="validationerror">Please enter gstNo </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">Email</label>
                                            <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please Enter email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="mobile" className="form-label">Phone Number</label>
                                            <input type="text" value={values.mobile} name="mobile" onChange={handleInputChange} className={"form-control" + applyErrorClass('mobile')} placeholder="Please Enter mobile" />
                                            {errors.mobile === false ? (<div className="validationerror">Please enter gstNo </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">TCS</label>
                                            <input type="number" value={values.tcs} name="tcs" onChange={handleInputChange} className={"form-control" + applyErrorClass('tcs')} placeholder="Please Enter tcs" />
                                            {errors.tcs === false ? (<div className="validationerror">Please enter tcs </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">Hotel GST</label>
                                            <input type="number" value={values.hotelGST} name="hotelGST" onChange={handleInputChange} className={"form-control" + applyErrorClass('hotelGST')} placeholder="Please Enter hotelGST" />
                                            {errors.hotelGST === false ? (<div className="validationerror">Please enter hotelGST </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="gstNo" className="form-label">Flight GST</label>
                                            <input type="number" value={values.flightGST} name="flightGST" onChange={handleInputChange} className={"form-control" + applyErrorClass('flightGST')} placeholder="Please Enter Flight GST" />
                                            {errors.flightGST === false ? (<div className="validationerror">Please enter flightGST </div>) : ('')}
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="hotelCommission" className="form-label">Hotel Commission</label>
                                            <input type="number" value={values.hotelCommission} name="hotelCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('hotelCommission')} placeholder="Please Enter hotelCommission" />
                                            {errors.hotelCommission === false ? (<div className="validationerror">Please enter hotelCommission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="flightCommission" className="form-label">Flight Commission</label>
                                            <input type="number" value={values.flightCommission} name="flightCommission" onChange={handleInputChange} className={"form-control" + applyErrorClass('flightCommission')} placeholder="Please Enter flightCommission" />
                                            {errors.flightCommission === false ? (<div className="validationerror">Please enter flightCommission </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="paymentGateway" className="form-label">Payment Gateway Fee</label>
                                            <input type="number" value={values.paymentGateway} name="paymentGateway" onChange={handleInputChange} className={"form-control" + applyErrorClass('paymentGateway')} placeholder="Please Enter paymentGateway" />
                                            {errors.paymentGateway === false ? (<div className="validationerror">Please enter paymentGateway </div>) : ('')}
                                        </div>
                                    </div> */}
                                    <div className="col-lg-2">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                        {
                                        btnSubmit === true ?
                                            <button type="submit" className="btn btn-primary">Update</button>
                                            :
                                            <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                        }
                                            <button type="button" className="btn btn-danger" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}