import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
import Select from 'react-select';
import Flatpickr from 'react-flatpickr'
export default function FlightBookings() {
    const [flightBooking, setFlightBooking] = useState([]);
    const [allFlights, setAllFlights] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [bookingStatus, setBookingStatus] = useState('ALL');
    const [flightBookingId, setFlightBookingId] = useState('00000000-0000-0000-0000-000000000000');
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetLastPageData = () => {
        GetFlightBookings(totalPages)
    }
    const GetFirstPageData = () => {
        GetFlightBookings("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetFlightBookings(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const handleSearch = (e) => {
        e.preventDefault();
        GetFlightBookings(pageNumber)
    }
    const GetFlightBookings = (number) => {
        const flightFilterSort = {
            "flightBookingId": flightBookingId.value,
            "status": bookingStatus
        }
        axios
            .post(config.APIACTIVATEURL + config.GETFLIGHTBOOKINGS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", flightFilterSort, { ...headerconfig })
            .then((response) => {
                if (response.data.data !== null) {
                    setFlightBooking(response.data.data.data);
                    setPageNumber(response.data.data.pageNumber);
                    setPageSize(response.data.data.pageSize);
                    setTotalPages(response.data.data.totalPages);
                    setData(response.data.data.data);
                    setTotalRecords(response.data.data.totalRecords);
                }
            });
    };
    const GetAllFlightBookings = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETALLFLIGHTBOOKINGS, { ...headerconfig })
            .then((response) => {
                setAllFlights(response.data.response.data);
            });
    };
    useEffect(() => {
        GetFlightBookings(pageNumber);
        GetAllFlightBookings();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Flight Bookings</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row g-5">
                                    <div className="col-xxl-6 col-lg-6">
                                        <div className="search-box">
                                            <Select options={allFlights} onChange={(e) => setFlightBookingId(e)} placeholder="Search by name, booking no, location" />
                                        </div>
                                    </div>
                                    <div className="col-xxl-2 col-lg-4">
                                        <select className="form-select" value={bookingStatus} onChange={e => setBookingStatus(e.target.value)}>
                                            <option value="ALL">ALL</option>
                                            <option value="CONFIRMED">Confirmed</option>
                                            <option value="PENDING">Pending</option>
                                            <option value="CANCELLED">Cancelled</option>
                                            <option value="COMPLETED">Completed</option>
                                        </select>
                                    </div>
                                    {/*end col*/}
                                    <div className="col-xxl-2 col-lg-4">
                                        <button className="btn btn-primary w-100" onClick={handleSearch}><i className="ri-search-line align-bottom me-1" /> Search</button>
                                    </div>
                                </div>
                                {/*end row*/}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Flight Bookings</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Booking No</th>
                                                        <th>Type</th>
                                                        <th>From</th>
                                                        <th>To</th>
                                                        <th>BookingDate</th>
                                                        <th>Departure Date</th>
                                                        <th>Name</th>
                                                        <th>Travellers</th>
                                                        <th>Price</th>
                                                        <th>Booking Status</th>
                                                        <th>Payment Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {flightBooking.length > 0 && flightBooking.map((v, index) =>
                                                        <tr key={v.flightBookingId}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to={"/flightbookingdetails/" + v.flightBookingId}>{v.bookingNo}</Link></td>
                                                            <td>{v.journeyType}</td>
                                                            <td>{v.locationFrom}</td>
                                                            <td>{v.locationTo}</td>
                                                            <td>{moment(v.bookingDate).format('Do MMM yyyy')}</td>
                                                            <td>{moment(v.departureDate).format('Do MMM yyyy')}</td>
                                                            <td>{v.travellerName}</td>
                                                            <td>{v.noOfTravellers}</td>
                                                            <td>{Math.round((v.totalPrice + Number.EPSILON) * 100) / 100}</td>
                                                            <td>
                                                                {v.bookingStatus === "CONFIRMED" ? <span className="badge bg-success">{v.bookingStatus}</span> : <span className="badge bg-danger">{v.bookingStatus}</span>}
                                                            </td>
                                                            <td>
                                                                {v.paymentStatus === "SUCCESS" ? <span className="badge bg-success">{v.paymentStatus}</span> : <span className="badge bg-danger">{v.paymentStatus}</span>}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{flightBooking.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}