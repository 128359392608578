import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const defaultPBImage = "/assets/images/default_holiday.jpg";
export default function UmrahEnquiryDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [details, setDetails] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken"),
        }
    }

    const GetDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETBYIDUMRAHENQUIRY + "/" + params.umrahEnquiryId, { ...headerconfig })
            .then((response) => {
                console.log(response.data.data);
                setDetails(response.data.data);
            });
    };
    useEffect(() => {
        GetDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="mb-3">Customer Umrah Enquiry Details</h4>
                                        <ul>
                                            <li>
                                                <label htmlFor="type" className="form-label">Package Name : {details?.umrahPackageName}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Name : {details?.name}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Email : {details?.email}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Mobile : {details?.mobile}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Nationality : {details?.nationality}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Status : {details?.status}</label>
                                            </li>
                                            <li>
                                            <label htmlFor="type" className="form-label">Departure From : {details?.departureFrom}</label>
                                            </li>
                                        </ul>
                                        <div className="row">
                                            <div className="col-3 mb-3">
                                                <img src={details?.profilePhotoSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.profilePhotoSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                            <div className="col-3 mb-3">
                                                <img src={details?.passportFrontSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.passportFrontSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                            <div className="col-3 mb-3">
                                                <img src={details?.passportBackSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.passportBackSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                            <div className="col-3 mb-3">
                                                <img src={details?.pancardSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.pancardSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                            <div className="col-3 mb-3">
                                                <img src={details?.aadharSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.aadharSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                            <div className="col-3 mb-3">
                                                <img src={details?.vaccinationSrc} style={{ width: "100%", height: "300px" }}></img>
                                                <Link to={details?.vaccinationSrc} target="_blank" className="btn btn-success">VIEW</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}