import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
import Select from 'react-select';
import Flatpickr from 'react-flatpickr'
export default function GroupFlights() {
    const [lists, setLists] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetGroupBookings = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETGROUPBOOKINGS, { ...headerconfig })
            .then((response) => {
                if (response.data.data.data !== null) {
                    setLists(response.data.data.data);
                }
            });
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEGROUPBOOKING + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetGroupBookings();
                    }
                    else {
                        handleError(response.data.data);
                        GetGroupBookings();
                    }
                });
        }
    }
    useEffect(() => {
        GetGroupBookings();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Group Bookings</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Group Bookings</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Travellers</th>
                                                        <th>Destination</th>
                                                        <th>Dates</th>
                                                        <th>Created</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {lists.length > 0 && lists.map((v, index) =>
                                                        <tr key={v.flightBookingId}>
                                                            <td>{v.name}</td>
                                                            <td>{v.email}</td>
                                                            <td>{v.mobile}</td>
                                                            <td>{v.groupFlights.length > 0 ? v.groupFlights[0].departureCode + " - " + v.groupFlights[0].arrivalCode : ""}</td>
                                                            <td>{v.groupFlights.length > 0 ? moment(v.groupFlights[0].departureDate).format('Do MMM yyyy') : ""}</td>
                                                            <td>Adults: {v.adults}, Kids: {v.kids} Infants: {v.infants}</td>
                                                            <td>{moment(v.createdDate).format('Do MMM yyyy')}</td>
                                                            <td>{v.status}</td>
                                                            <td>
                                                                <ul>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, v.groupBookingId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}