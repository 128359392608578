import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
const defaultPBImage = "/assets/images/default_holiday.jpg";
const initialFieldValues = {
    promoCodeId: "00000000-0000-0000-0000-000000000000",
    code: "",
    type:"DOMESTIC",
    value: "",
    description: "",
    status: true,
    imageName: "",
    imageFile: null,
    imageSrc: defaultPBImage
};
export default function PromoCode() {
    const [promoCodes, setPromoCodes] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: ""
            })
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };
    const validate = () => {
        let temp = {};
        temp.code = values.code === "" ? false : true;
        temp.status = values.status === "" ? false : true;
        temp.value = values.value === "" ? false : true;
        temp.description = values.description === "" ? false : true;
        temp.imageSrc = values.imageSrc === defaultPBImage ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("promoCodeId", values.promoCodeId)
            formData.append("code", values.code)
            formData.append("type", values.type)
            formData.append("status", values.status)
            formData.append("value", values.value)
            formData.append("description", values.description)
            formData.append("imageName", values.imageName)
            formData.append("imageFile", values.imageFile)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(config.APIACTIVATEURL + config.CREATEPROMOCODE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(config.APIACTIVATEURL + config.UPDATEPROMOCODE, updateRecord, { ...headerconfig }),
            delete: (id) => axios.delete(config.APIACTIVATEURL + config.DELETEPROMOCODE + "/" + id, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('promoCodeId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetPromoCodes(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in saving data");
                    setBtnSubmit(true);
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetPromoCodes(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                })
                .catch(function (error) {
                    handleError("Error in updating data");
                    setBtnSubmit(true);
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetPromoCodes(totalPages)
    }
    const GetFirstPageData = () => {
        GetPromoCodes("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetPromoCodes(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(config.APIACTIVATEURL + config.DELETEPROMOCODE + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        GetPromoCodes(pageNumber);
                        resetForm();
                    }
                    else {
                        handleError(response.data.data);
                        GetPromoCodes(pageNumber);
                        resetForm();
                    }
                });
        }
    }
    const GetPromoCodes = (number) => {
        axios
            .get(config.APIACTIVATEURL + config.GETPROMOCODES + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setPromoCodes(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetPromoCodes(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Promo Codes</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row mb-3">
                                    <div className="col-sm-3 col-12">
                                        <div className="picture-container">
                                            <div className="picture mb-3">
                                                <img src={values.imageSrc} className="picture-src" width="200px" height="200px" controls />
                                            </div>
                                            <input id="image-uploader" name="imageSrc" className={"form-control-file" + applyErrorClass('imageSrc')} type="file" onChange={showPreview} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="destinationName" className="form-label">Promo Code</label>
                                            <input type="text" onInput={toInputUppercase} value={values.code} name="code" onChange={handleInputChange} className={"form-control" + applyErrorClass('code')} placeholder="500 OFF, 1000 OFF..." />
                                            {errors.code === false ? (<div className="validationerror">Please enter code </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label">Type</label>
                                            <select name="type" value={values.type} onChange={handleInputChange} className={"form-select" + applyErrorClass('type')}>
                                                <option value={"DOMESTIC"}>DOMESTIC</option>
                                                <option value={"INTERNATIONAL"}>INTERNATIONAL</option>
                                            </select>
                                            {errors.type === false ? (<div className="validationerror">Please select type </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="destinationName" className="form-label">Amount</label>
                                            <input type="input" onInput={toInputUppercase} value={values.value} name="value" onChange={handleInputChange} className={"form-control" + applyErrorClass('value')} placeholder="500.." />
                                            {errors.value === false ? (<div className="validationerror">Please enter amount </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="mb-3">
                                            <label htmlFor="status" className="form-label">Status</label>
                                            <select name="isActive" value={values.isActive} onChange={handleInputChange} className={"form-select" + applyErrorClass('isActive')}>
                                                <option value={true}>ACTIVE</option>
                                                <option value={false}>INACTIVE</option>
                                            </select>
                                            {errors.isActive === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label htmlFor="destinationName" className="form-label">Description</label>
                                            <input type="text" onInput={toInputUppercase} value={values.description} name="description" onChange={handleInputChange} className={"form-control" + applyErrorClass('description')} placeholder="Diwali Offer" />
                                            {errors.description === false ? (<div className="validationerror">Please enter description </div>) : ('')}
                                        </div>
                                    </div>                                    
                                    <div className="col-lg-2">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {
                                                btnSubmit === true ?
                                                    <button type="submit" className="btn btn-primary w-100">{values.promoCodeId === "00000000-0000-0000-0000-000000000000" ? "Submit" : "Update"}</button>
                                                    :
                                                    <button type="submit" className="btn btn-primary w-100" disabled>Please wait...</button>
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">PromoCodes</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Image</th>
                                                        <th>Type</th>
                                                        <th>Code</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {promoCodes.length > 0 && promoCodes.map((v, index) =>
                                                        <tr key={v.promoCodeId}>
                                                            <td>{index + 1}</td>
                                                            <td><Link to={v.imageSrc} target="_blank"><img src={v.imageSrc} width={50} height={50}></img></Link></td>
                                                            <td>{v.type}</td>
                                                            <td>{v.code}</td>
                                                            <td>{v.status === true ? <span className="badge bg-success">ACTIVE</span> : <span className="badge bg-danger">INACTIVE</span>}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(v)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, v.promoCodeId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{promoCodes.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}