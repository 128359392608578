import { Link, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import config from "../../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../../Common/Layout/CustomAlerts";
import LayoutHeader from "../../Common/Layout/Header";
import LayoutSideBar from "../../Common/Layout/Sidebar";
import moment from "moment";
export default function HotelBookingDetails() {
    const params = useParams();
    const [hotelBooking, setHotelBooking] = useState('');
    const [hotel, setHotel] = useState({});
    const [rooms, setRooms] = useState([]);
    const [guests, setGuests] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    function getTimeFromMins(mins) {
        var h = mins / 60 | 0, m = mins % 60 | 0;
        var durationformat = h + "h " + m + "m";
        return durationformat;
    }
    const GetFlightBookingDetails = () => {
        axios
            .get(config.APIACTIVATEURL + config.GETHOTELBOOKINGBYID + "?Id=" + params.hotelBookingId, { ...headerconfig })
            .then((response) => {
                if (response.data.response.data !== null) {
                setHotelBooking(response.data.response.data);
                setHotel(response.data.response.data.hotelDetails)
                setRooms(response.data.response.data.hotelRoomDetails.requestedHotels);
                setGuests(response.data.response.data.hotelGuestInfos);
                }
            });
    };
    useEffect(() => {
        GetFlightBookingDetails();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Hotel Booking Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Booking No</b> : {hotelBooking.bookingNo}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Destination</b> : {hotelBooking.city},{hotelBooking.country}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Check-In </b> : {moment(hotelBooking.checkIn).format('Do MMM yyyy')}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Check-Out </b> : {moment(hotelBooking.checkOut).format('Do MMM yyyy')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Hotel</b> : {hotel.hotelName}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Address</b> : {hotel.address},{hotel.cityName},{hotel.countryName}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Phone Number</b> : {hotelBooking.phoneNumber}</label>
                                    </div>
                                </div>
                                <div className="row mb-3 mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Booking Status</b> : {hotelBooking.bookingStatus == "PENDING" ? <span className="badge bg-warning">PENDING</span> : hotelBooking.bookingStatus == "FAILED" ? <span className="badge bg-danger">FAILED</span> : <span className="badge bg-success">CONFIRMED</span>}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Payment Status</b> : {hotelBooking.paymentStatus == "PENDING" ? <span className="badge bg-danger">PENDING</span> : <span className="badge bg-success">SUCCESS</span>}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Payment Gateway</b> : {hotelBooking.paymentGatewayNo}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Invoice No</b> : {hotelBooking.invoiceNo}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>User Name</b> : {hotelBooking.travellerName}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Email</b> : {hotelBooking.travellerEmail}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Mobile</b> : {hotelBooking.travellerMobile}</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Base Price</b> : {Math.round((hotelBooking.hotelRoomDetails?.price + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                </div>
                                <div className="row mb-3">                                    
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Tax</b> : {Math.round((hotelBooking.taxAndFee + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>TCS</b> : {Math.round((hotelBooking.tcsAmount + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>PaymentGatewayFee</b> : {Math.round((hotelBooking.paymentGatewayFee + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                    <div className="col-lg-3">
                                        <label className="form-label"><b>Total Price</b> : {Math.round((hotelBooking.totalPrice + Number.EPSILON) * 100) / 100} INR</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Rooms</h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th></th>
                                                <th>Room Type</th>
                                                <th>Guests</th>
                                                <th>Meal Type</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {rooms.length > 0 && rooms.map((v, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td className="id">{v.roomType}</td>
                                                    <td className="id">Adults : {v.adults}, Kids: {v.kids}</td>
                                                    <td className="id">{v.mealType}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Guests</h3>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th className="text-center">Guest Name</th>
                                                <th className="text-center">Type</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {guests.length > 0 && guests.map((raa, index) =>
                                                <React.Fragment key={index}>
                                                    {raa.adultsFields.length > 0 && raa.adultsFields.map((ra, adultIndex) => (
                                                        <tr key={adultIndex}>
                                                            <td className="col-sm-3 text-center">{ra.title}. {ra.firstName} {ra.lastName}</td>
                                                            <td className="col-sm-3 text-center">{ra.travelerType}</td>
                                                        </tr>
                                                    ))}
                                                    {raa.kidsFields.length > 0 && raa.kidsFields.map((ra, adultIndex) => (
                                                        <tr key={adultIndex}>
                                                            <td className="col-sm-3 text-center">{ra.title}. {ra.firstName} {ra.lastName}</td>
                                                            <td className="col-sm-3 text-center">{ra.travelerType}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}